import { sharedTheme } from '@cof/plastic-components';

import veryTheme, { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { very, global },
} = sharedTheme;

const header = {
    text: {
        mobile: global.black,
        desktop: global.black,
    },
    link: {
        color: {
            mobile: global.black,
            desktop: global.black,
        },
        active: {
            mobile: very.pink500,
            desktop: very.pink500,
            border: global.white,
        },
        background: {
            mobile: global.white,
            desktop: global.transparent,
            active: {
                mobile: global.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
        border: {
            mobile: global.grey200,
        },
    },
    navBar: {
        // mobile: COLOR_NAMES.SHADE_2,
        border: {
            tablet: global.white,
        },
        background: {
            mobile: global.transparent,
            desktop: global.transparent,
        },
    },
    background: {
        mobile: global.white,
        desktop: very.blue750,
    },
    border: {
        mobile: global.grey200,
    },
    icon: {
        active: 'currentColor',
        inactive: 'currentColor',
    },
    helpIcon: global.white,
};

const pageHeader = {
    row: global.grey50,
    text: very.pink500,
    emergencyBanner: {
        background: very.teal600,
    },
};

const defaultExport: ComponentColors = {
    ...veryTheme,
    header,
    pageHeader,
};

export default defaultExport;
