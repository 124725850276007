import { styled } from 'styled-components';

import { Accordion, Box, InfoPanel, OneColumn, Text } from '@cof/plastic-components';

import { RecentTransactionItem, useRecentTransactionsData } from '../../../data/transactions';
import Transaction from './Transaction';

const TransactionListHeading = styled(Box)`
    ${({ theme }) => theme.components.infoPanel.panelBackground}
`;

export function RecentTransactions() {
    const recentTransactions = useRecentTransactionsData();

    if (recentTransactions.isPending) {
        return (
            <TransactionListHeading>
                <Text>Loading recent transactions...</Text>
            </TransactionListHeading>
        );
    } else if (recentTransactions.isError) {
        return (
            <TransactionListHeading>
                <Text data-qa-id="recent-error">Sorry! There was an error retrieving your recent transactions.</Text>
            </TransactionListHeading>
        );
    }

    return (
        <OneColumn
            Content={
                <>
                    {recentTransactions.data?.length && recentTransactions.data.length > 0 ? (
                        recentTransactions.data.map(({ date, items }) => (
                            <Box key={date}>
                                <TransactionListHeading>
                                    <Text data-qa-id="transaction-date">
                                        {new Intl.DateTimeFormat('en-GB', {
                                            weekday: 'long',
                                            month: 'long',
                                            day: 'numeric',
                                        }).format(new Date(date))}
                                    </Text>
                                </TransactionListHeading>
                                <Accordion
                                    data-qa-id="recent-transaction-items"
                                    items={items.map((item: RecentTransactionItem, index) => (
                                        <Transaction key={index} {...item} />
                                    ))}
                                />
                            </Box>
                        ))
                    ) : (
                        <InfoPanel>
                            <Text>You have no other recent transactions.</Text>
                        </InfoPanel>
                    )}
                </>
            }
        />
    );
}
