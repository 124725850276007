import cssSystem from '@styled-system/css';
import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

export default {
    icon: ({ theme }: { theme: DefaultTheme }) => css`
        ${cssSystem({
            color: theme.componentColors.balanceTransfer.iconOnBtPage,
        })}
    `,
};
