import { ComponentType } from 'react';

import {
    BRANDS,
    BellIcon,
    Box,
    Flex,
    Paragraph,
    PaymentsIcon,
    THEME_UTILITIES,
    useBrand,
} from '@cof/plastic-components';
import type { IconProps, TwoToneIconProps } from '@cof/plastic-components';

import { useAtAGlanceData } from '../../../data/atAGlance';

type IconWithRef = ComponentType<IconProps | TwoToneIconProps>;

const { useColor } = THEME_UTILITIES;

export default () => {
    const { data: atAGlanceData } = useAtAGlanceData();
    const brand = useBrand();

    const iconColor = useColor({
        capitalOne: 'capitalOne.pink250',
        ASOS: 'ASOS.purple350',
        ocean: 'ocean.blue800',
        postOffice: 'postOffice.red549',
        fallback: 'capitalOne.pink250',
    });
    const bellIcon: IconWithRef = () => <BellIcon mr="xs" color={iconColor} size={36} />;
    const moneyIcon: IconWithRef = () => <PaymentsIcon mr="xs" color={iconColor} size={36} />;

    const ContextualMessage = ({
        index,
        Icon,
        message,
        qaId,
    }: {
        index: number;
        Icon: IconWithRef;
        message: string;
        qaId: string;
    }) => {
        return (
            <Flex as="li" key={index} alignItems="center" py="xs" data-qa-id={`${qaId}-${index + 1}`}>
                <Box flexShrink="0">
                    <Icon />
                </Box>
                <Paragraph
                    data-qa-id={`${qaId}-text`}
                    pb={0}
                    size="large"
                    variant={brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark'}
                >
                    {message}
                </Paragraph>
            </Flex>
        );
    };

    const contextualMessages = atAGlanceData?.contextualMessages ?? [];
    const previousPayment = sessionStorage.getItem('previousPayment');

    if ((Array.isArray(contextualMessages) && contextualMessages.length > 0) || previousPayment) {
        return (
            <ul data-qa-id="contextual-messages">
                {contextualMessages.map((message, index) => (
                    <ContextualMessage
                        key={index}
                        index={index}
                        Icon={bellIcon}
                        message={message}
                        qaId="contextual-message"
                    />
                ))}
                {previousPayment && (
                    <ContextualMessage
                        message={`Your recent payment of ${previousPayment} is being processed and may take 2 working days to show in your balance`}
                        Icon={moneyIcon}
                        qaId="contextual-message-payment"
                        index={0}
                    />
                )}
            </ul>
        );
    } else {
        return <></>;
    }
};
