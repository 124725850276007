import type { To } from 'history';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import {
    BRANDS,
    Box,
    BoxProps,
    Heading,
    HeadingProps,
    PageRowNoContentPadding,
    PageRowProps,
    THEME_CONSTANTS,
    useBrand,
} from '@cof/plastic-components';

import RouterBackButton from '../RouterBackButton/RouterBackButton';

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

type WithoutBackLink = {
    withBackLink?: false;
    to?: never;
    backLinkText?: never;
};

type WithBackLink = {
    withBackLink: true;
    to: To;
    backLinkText?: string;
};

type PageHeaderProps = { children: ReactNode } & (WithBackLink | WithoutBackLink);

export const PageMainHeading: React.FC<{ children: string } & Partial<HeadingProps>> = ({
    children,
    ...otherProps
}) => {
    const theme = useTheme();
    return (
        <Heading level="1" color={theme.componentColors.pageHeader.text} textAlign="left" {...otherProps}>
            {children}
        </Heading>
    );
};

export const PageHeaderRow: React.FC<{ children: ReactNode } & Partial<PageRowProps>> = ({
    children,
    ...otherProps
}) => {
    const theme = useTheme();

    return (
        <PageRowNoContentPadding
            py={0}
            pt={['md', 'lg']}
            pb={0}
            wrapperBg={theme.componentColors.pageHeader.row}
            maxWidth={SCREEN_WIDTHS.TEN_COLUMN}
            {...otherProps}
        >
            {children}
        </PageRowNoContentPadding>
    );
};

export const PageHeaderMainContent: React.FC<{ children: ReactNode } & Partial<BoxProps>> = ({
    children,
    ...otherProps
}) => {
    return (
        <Box pt={[null, 'sm']} pb={[null, 'xl']} mb={['sm', 'sm', 'md', 'lg']} {...otherProps}>
            {typeof children === 'string' ? <PageMainHeading>{children}</PageMainHeading> : children}
        </Box>
    );
};

export const PageHeader: React.FC<PageHeaderProps> = ({ children, withBackLink, to, backLinkText }) => {
    const brand = useBrand();
    return (
        <PageHeaderRow>
            {withBackLink && (
                <Box mb={['sm', 0]}>
                    <RouterBackButton
                        to={to}
                        variant={brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark'}
                        children={backLinkText ?? undefined}
                    />
                </Box>
            )}
            <PageHeaderMainContent>{children}</PageHeaderMainContent>
        </PageHeaderRow>
    );
};
