import type { ComponentType } from 'react';

import type { BoxProps } from '@cof/plastic-components';

import { FlexWithBrandGrey } from '../Flex/Flex';

type DataRowProps = { Left?: React.ReactNode; Right?: React.ReactNode; as?: string | ComponentType<unknown> } & Omit<
    BoxProps,
    'color'
>;

export const DataRow = ({ Left, Right, ...otherProps }: DataRowProps) => (
    <FlexWithBrandGrey
        backgroundColor="brand.grey50"
        p="sm"
        px="md"
        justifyContent="space-between"
        alignItems="center"
        {...otherProps}
    >
        {Left}
        {Right}
    </FlexWithBrandGrey>
);
