import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';

const currencySchema = z.object({
    value: z.string(),
    currency: z.string(),
});

export enum PaymentTypes {
    MINIMUM = 'MINIMUM',
    FULL = 'FULL',
    FIXED = 'FIXED',
}

export const paymentInfoValidator = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(PaymentTypes.FIXED),
        canAmend: z.boolean(),
        amount: currencySchema,
        minimumFixedAmount: currencySchema,
        maximumFixedAmount: currencySchema,
    }),
    z.object({
        type: z.literal(PaymentTypes.MINIMUM),
        canAmend: z.boolean(),
        minimumFixedAmount: currencySchema,
        maximumFixedAmount: currencySchema,
    }),
    z.object({
        type: z.literal(PaymentTypes.FULL),
        canAmend: z.boolean(),
        minimumFixedAmount: currencySchema,
        maximumFixedAmount: currencySchema,
    }),
]);

export const directDebitValidator = z.discriminatedUnion('showDirectDebit', [
    z.object({
        isSetupNearDueDate: z.boolean(),
        paymentInfo: paymentInfoValidator,
        account: z.object({
            name: z.string(),
            obfuscatedBAN: z.string(),
            sortCode: z.string(),
        }),
        cycle: z.object({
            paymentTakeDate: z.string().nullable(),
        }),
        showDirectDebit: z.literal(true),
    }),
    z.object({ showDirectDebit: z.literal(false) }),
]);

export type DirectDebitData = z.infer<typeof directDebitValidator>;

export const useDirectDebitData = () => {
    return useQuery({
        queryKey: ['direct-debit'],
        queryFn: async () => {
            const path = ORCH_PATHS.DIRECT_DEBIT;
            try {
                const response = await orchestration.get(path);
                return directDebitValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};
