import { useIsMutating } from '@tanstack/react-query';
import { ReactNode, Suspense, useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import {
    ApplicationLayout,
    BrandedFooter,
    Flex,
    LoadingSpinnerBranded,
    LoadingSpinnerPortal,
    SkipLink,
    THEME_CONSTANTS,
} from '@cof/plastic-components';

import DelayedSpinner from '../../atoms/DelayedSpinner/DelayedSpinner';
import SessionManager from '../../atoms/SessionManager/SessionManager';
import { useAtAGlanceData } from '../../data/atAGlance';
import { useCreateOrGetSession } from '../../data/session';
import useGlobalTimeout from '../../hooks/useGlobalTimeout';
import { REDIRECT_URI_KEY, verifyRedirectURI } from '../../hooks/useRedirectURI';
import Header from '../../molecules/Header';
import { BRAND_MAP_WS_TO_PLASTIC, BrandStateContext } from '../../utils/brand';
import { PATHS } from '../../utils/constants';
import Divider from './Divider';

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

const Main = ({ children, ...otherProps }: { children: ReactNode }) => (
    <Flex as="main" id="main" {...otherProps}>
        {children}
    </Flex>
);

export default () => {
    // sets global timeout of 30.5 mins
    useGlobalTimeout();

    const { data: sessionData, isPending: isPendingSession, isError: isSessionError } = useCreateOrGetSession();
    const { data: atAGlanceData, isError: isAtAGlanceError, isLoading } = useAtAGlanceData({ enabled: !!sessionData });
    const isLoggingOut = useIsMutating({ mutationKey: ['logout'] });
    const [, setBrand] = useContext(BrandStateContext);
    const navigate = useNavigate();

    // set the current brand from the ataglance call (and store it in localStorage for next time)
    useEffect(() => {
        if (atAGlanceData && BRAND_MAP_WS_TO_PLASTIC[atAGlanceData.brand]) {
            setBrand(BRAND_MAP_WS_TO_PLASTIC[atAGlanceData.brand]);
            localStorage.setItem('brand', BRAND_MAP_WS_TO_PLASTIC[atAGlanceData.brand]);
        }
    }, [atAGlanceData, setBrand]);

    useEffect(() => {
        const redirectUriFromLocalStorage = localStorage.getItem(REDIRECT_URI_KEY);
        if (redirectUriFromLocalStorage) {
            localStorage.removeItem(REDIRECT_URI_KEY);
            const redirectUri = verifyRedirectURI(redirectUriFromLocalStorage);
            if (redirectUri) navigate(redirectUri);
        }
    }, [navigate]);

    return isPendingSession || isLoading || isLoggingOut ? (
        <LoadingSpinnerPortal domElementId="root" LoadingSpinner={LoadingSpinnerBranded} />
    ) : isSessionError ? (
        <Navigate to={PATHS.AUTH_ERROR} replace />
    ) : isAtAGlanceError ? (
        <Navigate to={PATHS.UNAUTHENTICATED_ERROR} replace />
    ) : (
        <>
            <SessionManager />
            <SkipLink href="#main" />
            <ApplicationLayout
                Header={Header}
                Footer={() => (
                    <>
                        <Divider />
                        <BrandedFooter maxWidth={SCREEN_WIDTHS.TEN_COLUMN_NO_PADDING} />
                    </>
                )}
                renderContentAs={Main}
            >
                <Suspense fallback={<DelayedSpinner />}>
                    <Outlet />
                </Suspense>
            </ApplicationLayout>
        </>
    );
};
