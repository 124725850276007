import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';
import { currencyValueSchema } from './common';

// common ----------------------------------------------------

const transactionLocationSchema = z.object({
    label: z.string(),
    city: z.string(),
    postcode: z.string(),
    country: z.string(),
});
export type TransactionLocation = z.infer<typeof transactionLocationSchema>;

const transactionItemSchema = z.object({
    name: z.string(),
    date: z.string(),
    location: transactionLocationSchema,
    value: currencyValueSchema,
    classification: z.string(),
});

// recent transactions -----------------------------------------

const recentTransactionItemSchema = transactionItemSchema.extend({
    postedDate: z.string(),
});
export type RecentTransactionItem = z.infer<typeof recentTransactionItemSchema>;

const recentTransactionsValidator = z.array(
    z.object({
        date: z.string(),
        items: z.array(recentTransactionItemSchema),
    }),
);

// pending transactions ------------------------------------------

export type PendingTransactionItem = z.infer<typeof transactionItemSchema>;

const pendingTransactionsValidator = z.object({
    total: currencyValueSchema,
    items: z.array(transactionItemSchema),
});

// data fetchers -------------------------------------------------

export const usePendingTransactionsData = () => {
    return useQuery({
        queryKey: ['pending-transactions'],
        queryFn: async () => {
            const path = ORCH_PATHS.TRANSACTIONS_PENDING;
            try {
                const response = await orchestration.get(path);
                return pendingTransactionsValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};

export const useRecentTransactionsData = () => {
    return useQuery({
        queryKey: ['recent-transactions'],
        queryFn: async () => {
            const path = ORCH_PATHS.TRANSACTIONS_RECENT;
            try {
                const response = await orchestration.get(path);
                return recentTransactionsValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};
