import { Navigate, useRouteError } from 'react-router-dom';

import { PATHS } from '../../utils/constants';
import reportError, { ReportableError } from '../../utils/reportError';

const ErrorBoundary = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const error = useRouteError();
    // Only report error if it hasn't already been handled
    if (!(error instanceof ReportableError)) {
        reportError(error);
    }

    return <Navigate to={isAuthenticated ? PATHS.AUTHENTICATED_ERROR : PATHS.UNAUTHENTICATED_ERROR} replace={true} />;
};

export default ErrorBoundary;
