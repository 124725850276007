import { z } from 'zod';

export const currencyValueSchema = z.object({
    currencyCode: z.string(),
    amount: z.number(),
});

// At a Glance & Account Summary

export const statementSchema = z.object({
    balance: currencyValueSchema,
    minimumPayment: currencyValueSchema,
    missedPayments: currencyValueSchema,
    nextPaymentDueBy: z.string().nullable(),
    overlimitAmount: currencyValueSchema,
    paymentDueAmount: currencyValueSchema,
});
