import { sharedTheme } from '@cof/plastic-components';

import { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { postOffice, global },
} = sharedTheme;

const paymentModal = {
    protectionFinanceIcon: postOffice.red550,
};

const header = {
    background: {
        mobile: global.white,
        desktop: postOffice.grey50,
    },
    text: {
        mobile: postOffice.grey700,
        desktop: postOffice.grey700,
    },
    link: {
        border: {
            mobile: global.grey100,
        },
        color: {
            mobile: postOffice.grey700,
            desktop: postOffice.grey700,
        },
        active: {
            mobile: postOffice.grey700,
            desktop: postOffice.grey700,
            border: global.grey200,
        },
        background: {
            mobile: global.white,
            desktop: global.transparent,
            active: {
                mobile: postOffice.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
    },
    helpIcon: postOffice.grey700,
    navBar: {
        border: {
            tablet: global.grey300,
        },
        background: {
            mobile: global.transparent,
            desktop: postOffice.grey50,
        },
    },
    icon: {
        active: postOffice.red550,
        inactive: 'currentColor',
    },
    border: {
        mobile: postOffice.grey50,
    },
};

const balanceTransfer = {
    icon: postOffice.teal600,
    iconOnBtPage: postOffice.red549,
};

const currencyConverter = {
    heading: {
        background: postOffice.grey50,
        text: postOffice.blue750,
    },
    result: postOffice.teal600,
};

const pageHeader = {
    row: postOffice.grey50,
    text: postOffice.grey700,
    emergencyBanner: {
        background: postOffice.teal600,
    },
};

const secondaryNav = {
    button: {
        default: {
            mobile: postOffice.grey700,
            mobileIcon: postOffice.grey700,
            desktop: postOffice.grey700,
            desktopIcon: postOffice.grey700,
        },
        expanded: postOffice.grey700,
        expandedIcon: postOffice.grey700,
        backgroundColor: global.white,
    },
    badge: {
        color: global.white,
        backgroundColor: postOffice.red549,
    },
    contentWrapper: {
        backgroundColor: global.white,
        border: global.grey100,
    },
};

const secureMessages = {
    infoIcon: { 1: postOffice.orange500, 2: postOffice.teal600 },
    noMessagesIcon: postOffice.teal600,
    customer: {
        background: postOffice.blue750,
        text: global.white,
    },
    messageHistoryBackground: global.grey50,
    emailIcon: postOffice.pink250,
    tableDivider: global.grey200,
    agent: {
        background: global.grey50,
        text: postOffice.blue750,
    },
};

const dataRow = {
    dark: {
        background: postOffice.grey50,
    },
    light: {
        background: global.white,
    },
};

const box = {
    background: postOffice.grey50,
    border: postOffice.grey50,
};

const accordion = {
    background: postOffice.grey50,
    border: postOffice.grey50,
    wrapper: {
        dark: postOffice.grey50, //Plastic default is global.grey50. Remove this when plastic is updated
    },
};

const infoPanel = {
    background: postOffice.grey50,
};

const permissions = {
    permissionCard: {
        active: postOffice.teal450,
        inactive: postOffice.red550,
    },
    endDateBox: {
        active: postOffice.teal450,
        inactive: postOffice.red550,
    },
    accountPermissions: {
        icon: postOffice.teal600,
    },
};

const transactions = {
    text: {
        credit: postOffice.green650,
        standard: postOffice.blue750,
        overlimit: postOffice.red550,
        link: postOffice.teal600,
        debit: postOffice.red550,
    },
    flag: {
        credit: postOffice.green650,
        standard: global.transparent,
        overlimit: postOffice.red550,
        link: postOffice.teal600,
        debit: postOffice.red550,
    },
    icon: postOffice.teal600,
};

const defaultExport: ComponentColors = {
    accordion,
    box,
    infoPanel,
    header,
    dataRow,
    balanceTransfer,
    currencyConverter,
    pageHeader,
    paymentModal,
    permissions,
    secondaryNav,
    secureMessages,
    transactions,
};

export default defaultExport;
