import { useEffect } from 'react';

import useLogout from '../data/mutators/logout';
import { PATHS } from '../utils/constants';

/**
 * Global timeout of 30.5 minutes
 */
const GLOBAL_TIMEOUT = 30.5 * 60 * 1000;

const useGlobalTimeout = () => {
    const { mutate: logout } = useLogout(PATHS.TIMEOUT);
    // Logs the user out after 30.5 mins no matter if they're still active
    useEffect(() => {
        const globalTimeout = setInterval(() => {
            clearInterval(globalTimeout);
            logout();
        }, GLOBAL_TIMEOUT);
        return () => {
            clearInterval(globalTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useGlobalTimeout;
