import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';

const clipOfferValidator = z.object({
    clOfferEndDate: z.string().optional(),
    clOfferTypeCode: z.string().optional(),
    gotoCreditLimit: z.number().optional(),
    isEligible: z.boolean(),
    reason: z.string().optional(),
});

export const useClipOfferData = () => {
    return useQuery({
        queryKey: ['clip-offer'],
        queryFn: async () => {
            const path = ORCH_PATHS.CLIP_OFFER;
            try {
                const response = await orchestration.get(path);
                return clipOfferValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};
