import { sharedTheme } from '@cof/plastic-components';

import c1Theme, { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { global, ASOS: asos, capitalOne: c1 },
} = sharedTheme;

const header = {
    background: {
        mobile: global.white,
        desktop: global.grey50,
    },
    border: {
        mobile: global.grey50,
        desktop: asos.grey750,
    },
    text: {
        mobile: asos.grey850,
        desktop: asos.grey850,
    },
    helpIcon: asos.grey850,
    icon: {
        active: 'currentColor',
        inactive: 'currentColor',
    },
    link: {
        border: {
            mobile: global.grey50,
        },
        color: {
            mobile: asos.grey850,
            desktop: asos.grey850,
        },
        active: {
            mobile: asos.purple350,
            desktop: asos.purple350,
            border: asos.purple350,
        },
        background: {
            mobile: global.transparent,
            desktop: global.transparent,
            active: {
                mobile: global.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
    },
    navBar: {
        border: {
            tablet: global.black,
        },
        background: {
            mobile: global.transparent,
            desktop: global.grey50,
        },
    },
};

const balanceTransfer = {
    icon: asos.purple350,
    iconOnBtPage: asos.purple350,
};

const currencyConverter = {
    heading: {
        background: asos.grey50,
        text: asos.grey850,
    },
    result: asos.purple350,
};

const pageHeader = {
    row: global.grey50,
    text: asos.grey850,
    emergencyBanner: {
        background: asos.grey750,
    },
};

const permissions = {
    permissionCard: {
        active: asos.purple350,
        inactive: c1.red500,
    },
    endDateBox: {
        active: asos.purple350,
        inactive: c1.red550,
    },
    accountPermissions: {
        icon: asos.purple350,
    },
};

const secondaryNav = {
    badge: {
        color: global.grey50,
        backgroundColor: c1.red500,
    },
    button: {
        default: {
            mobile: asos.grey850,
            mobileIcon: asos.grey850,
            desktop: asos.grey850,
            desktopIcon: asos.grey850,
        },
        expanded: asos.grey850,
        expandedIcon: asos.grey850,
        backgroundColor: global.grey50,
    },
    contentWrapper: {
        backgroundColor: global.grey50,
        border: global.grey50,
    },
};

const secureMessages = {
    noMessagesIcon: asos.purple350,
    emailIcon: asos.purple350,
    infoIcon: { 1: asos.orange500, 2: asos.purple350 },
    customer: {
        background: asos.blue750,
        text: global.white,
    },
    messageHistoryBackground: global.grey50,
    tableDivider: global.grey200,
    agent: {
        background: global.grey50,
        text: asos.blue750,
    },
};

const transactions = {
    text: {
        standard: asos.grey850,
        link: asos.grey850,
        credit: asos.green650,
        overlimit: asos.red550,
        debit: asos.red550,
    },
    flag: {
        link: asos.grey850,
        credit: asos.green650,
        standard: global.transparent,
        overlimit: asos.red550,
        debit: asos.red550,
    },
    icon: asos.grey850,
};

const paymentModal = {
    protectionFinanceIcon: asos.purple350,
};

const { box, accordion, infoPanel, dataRow } = c1Theme;

const defaultExport: ComponentColors = {
    box,
    accordion,
    infoPanel,
    dataRow,
    header,
    balanceTransfer,
    currencyConverter,
    pageHeader,
    paymentModal,
    permissions,
    secondaryNav,
    secureMessages,
    transactions,
};

export default defaultExport;
