import { ComponentType, ReactNode } from 'react';
import { styled } from 'styled-components';

import { Button, ButtonProps, Flex, IconProps } from '@cof/plastic-components';

const StyledIconButton = styled(Button)`
    ${({ theme }) => theme.components.iconButton.button}
`;

const DisplayIcon = styled.span``;
const IconButton = ({
    Icon,
    children,
    ...props
}: { Icon: ComponentType<IconProps> | undefined; children: ReactNode } & ButtonProps) => {
    return (
        <StyledIconButton variant="textLinkInline" textSize="large" {...props}>
            <Flex alignItems="center">
                {children}
                <DisplayIcon as={Icon} pl="xs" />
            </Flex>
        </StyledIconButton>
    );
};

export default IconButton;
