import { BellIcon, Flex } from '@cof/plastic-components';

import { DataRow } from '../../../atoms/DataRow/DataRow';
import RouterLink from '../../../atoms/RouterLink';
import { useClipOfferData } from '../../../data/clipOffer';
import { PATHS } from '../../../utils/constants';

const ClipOfferBanner = () => {
    const { data: clipOfferData, isError } = useClipOfferData();
    return (
        <>
            {/* From SMTD: If the call fails or the customer doesn't have a CLIP offer (isEligible:false) then the banner will not be shown */}
            {isError ? (
                <></>
            ) : (
                <>
                    {/* From SMTD: The banner will only be shown if a user has an opt-in or opt-out CLIP offer */}
                    {/* REACTIVE_CLI offers are not shown, if the type is REACTVE_CLI, orch returns isEligible=false */}
                    {clipOfferData?.isEligible && (
                        <DataRow
                            data-qa-id="clip-banner"
                            Left={
                                <RouterLink to={PATHS.CLIP}>
                                    <Flex alignItems="center">
                                        <BellIcon mr="xs" /> You have a credit limit increase offer
                                    </Flex>
                                </RouterLink>
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ClipOfferBanner;
