import { useEffect, useState } from 'react';

import { LoadingSpinnerBranded } from '@cof/plastic-components';

export default () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return <>{show && <LoadingSpinnerBranded data-qa-id="lazy-loading-spinner" py="xl" />}</>;
};
