import { ComponentType } from 'react';
import { css, styled } from 'styled-components';

import { BalanceTransferIcon, Box, InfoPanel, Link, Paragraph } from '@cof/plastic-components';

import RouterLink from '../../../atoms/RouterLink';
import { BannerState, useAtAGlanceData } from '../../../data/atAGlance';
import { PATHS } from '../../../utils/constants';

const AmountCorrectionView = () => {
    const { data, isError } = useAtAGlanceData();

    if (!data || isError || !data.balanceTransfer?.eligible) {
        return <></>;
    }

    const { totalValue: total, minimumAmount: minimum, maximumAmount: maximum } = data.balanceTransfer;

    return (
        <>
            <Paragraph data-qa-id="eligible-banner-title" size="large" fontWeight="semiBold">
                We could not process your balance transfer
            </Paragraph>
            <Paragraph>
                Your original request of £{total} was too high. You can transfer between £{minimum} and £{maximum}.
            </Paragraph>
            <Paragraph>
                Please{' '}
                <RouterLink data-qa-id="balance-transfer-link" to={PATHS.BALANCE_TRANSFER}>
                    edit your original request
                </RouterLink>{' '}
                to complete your balance transfer.
            </Paragraph>
        </>
    );
};

const TransferTooHighView = () => (
    <>
        <Paragraph data-qa-id="too-high-banner-title" size="large" fontWeight="semiBold">
            There was a problem with your balance transfer
        </Paragraph>
        <Paragraph>
            The amount you asked for is more than you're allowed to transfer to this account. To try again with a lower
            amount, please call us on <Link href="tel:03444810890">03444 810890</Link>.
        </Paragraph>
    </>
);

const CallUsView = () => (
    <>
        <Paragraph data-qa-id="too-low-banner-title" size="large" fontWeight="semiBold">
            Looks like you still need to transfer a balance
        </Paragraph>
        <Paragraph>
            Please call us on <Link href="tel:03444810890">03444 810890</Link>. You'll need your Capital One card to
            hand as well as the account details of the card you're transferring from.
        </Paragraph>
    </>
);

const Separator = () => <Box my="xl" />;

const BannerViews: Record<BannerState, ComponentType> = {
    AMOUNT_CORRECTION: AmountCorrectionView,
    BT_TOO_HIGH_CALL_US: TransferTooHighView,
    CALL_US: CallUsView,
};

const StyledBalanceTransferIcon = styled(BalanceTransferIcon)`
    ${({ theme }) => css`
        color: ${theme.componentColors.balanceTransfer.icon};
    `};
`;

export default function BalanceTransferBanner() {
    const { data, isError } = useAtAGlanceData();

    if (!data || isError || !data.balanceTransfer?.eligible) {
        return <Separator />;
    }

    const BannerView = BannerViews[data.balanceTransfer.bannerState];

    return (
        <Box my="lg">
            <InfoPanel data-qa-id="balance-transfer-banner" Icon={StyledBalanceTransferIcon}>
                <BannerView />
            </InfoPanel>
        </Box>
    );
}
