import { styled } from 'styled-components';

import type { AccordionItemProps, TextProps } from '@cof/plastic-components';
import { AccordionItem, Box, Flex, Paragraph, Text } from '@cof/plastic-components';

import { PendingTransactionItem, TransactionLocation } from '../../../data/transactions';
import { formatCurrencyValue } from '../../../utils/formatters';

const TransactionValue = styled(Text)<TextProps & { classification: string }>`
    ${({ theme }) => theme.components.transactions.valueText}
`;

const TransactionItem = styled(AccordionItem)<AccordionItemProps & { classification: string }>`
    ${({ theme }) => theme.components.transactions.transactionSummary}
`;

const TransactionSummaryText = styled(Text)`
    text-decoration: none;
    display: inline-block;
`;

const TransactionDetail = ({ address, date }: { address: TransactionLocation; date?: string }) => {
    // Adds line breaks between every element in an array.
    const addLineBreaks = (elements: JSX.Element[]) => {
        return elements.flatMap((element, index) => [element, <br key={index} />]).slice(0, -1);
    };

    let addressElements: JSX.Element[] = [];
    const selectors = ['city', 'postcode', 'country'] as (keyof TransactionLocation)[];
    for (const selector of selectors) {
        const addressLine = address[selector];
        if (addressLine !== '') {
            addressElements.push(<Text key={selector}>{addressLine}</Text>);
        }
    }

    if (addressElements.length === 0) {
        addressElements.push(<Text key="no-address">There is no address information for this transaction.</Text>);
    }

    addressElements = addLineBreaks(addressElements);

    return (
        <Flex flexDirection={['column', null, 'row']} pb="sm">
            <Box flex={[1, null, 4]}>
                <Paragraph data-qa-id="transaction-merchant-postcode">{addressElements}</Paragraph>
            </Box>
            {date && (
                <Box flex={[1, null, 8]}>
                    <Text style={{ fontStyle: 'italic' }}>
                        Posted on{' '}
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        }).format(new Date(date))}
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

type TransactionProps = Omit<PendingTransactionItem, 'date'> & { postedDate?: string };

const Transaction = ({ name, location, value, classification, postedDate }: TransactionProps) => (
    <TransactionItem
        heading={
            <>
                <Flex justifyContent="space-between">
                    <Box flex="4">
                        <TransactionSummaryText data-qa-id="transaction-merchant-text">{name}</TransactionSummaryText>
                    </Box>
                    <Box flex="6" display={['none', null, 'block']}>
                        <TransactionSummaryText data-qa-id="transaction-location">
                            {location.label}
                        </TransactionSummaryText>
                    </Box>
                    <Box flex="2" textAlign="right">
                        <TransactionValue
                            data-qa-id="transaction-amount"
                            classification={classification}
                            fontWeight="medium"
                            textAlign="right"
                        >
                            {formatCurrencyValue(value.amount)}
                        </TransactionValue>
                    </Box>
                </Flex>
            </>
        }
        classification={classification}
    >
        <TransactionDetail address={location} date={postedDate} />
    </TransactionItem>
);

export default Transaction;
