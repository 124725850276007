import { BRANDS, Markdown, Paragraph, useBrand } from '@cof/plastic-components';

import { NON_BRANDED_LINKS, PATHS } from '../../../utils/constants';
import ErrorPageTemplate from '../ErrorPageTemplate';

const pageContent = `
* Go back to [Payments](${PATHS.PAYMENTS}) and try again
* Make a payment in the [app](${NON_BRANDED_LINKS.OUR_APP})
* If you need help quickly, call us on [${NON_BRANDED_LINKS.PAYMENTS_TEL_NUMBER}](tel:${NON_BRANDED_LINKS.PAYMENTS_TEL_NUMBER})
`;

export default function PaymentError() {
    const brand = useBrand();
    const textVariant = brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark';
    return (
        <ErrorPageTemplate headingText="Sorry, something went wrong">
            <Paragraph mb="sm" size="large" variant={textVariant}>
                Here are some things you can do:
            </Paragraph>
            <Markdown variant={textVariant}>{pageContent}</Markdown>
        </ErrorPageTemplate>
    );
}
