import { convertToLenientBrandMap } from './brand';

export const PATHS = {
    ACCESS_REVOKED: '/access-changed',
    AT_A_GLANCE: '/',
    AUTH_ERROR: '/auth-error',
    AUTHENTICATED_ERROR: '/error',
    BALANCE_TRANSFER: '/manage-balance-transfer',
    CLIP: '/clip',
    CLIP_OFFER: '/clip-offer',
    COMPLAINT: '/complaint',
    CONTACT_DETAILS: `/contact-details`,
    CURRENCY_CONVERTER: '/currency-converter',
    DEEP_LINKING: '/deep-linking',
    HELP: '/help',
    LOGIN: `${window.config.ui.oldUrl}/login`,
    LOGOUT: '/sign-out',
    MANAGE_DD: '/manage-direct-debit',
    MY_CARD: '/mycard',
    PAYMENTS: '/payments',
    MOBILE_PAY_BY_BANK_RESULT: '/mobile/pay-by-bank',
    PAYMENT_NOT_PERMITTED_ERROR: '/payment-error-call-us',
    PERMISSIONS: '/permissions',
    PREFERENCES: '/preferences',
    SECURE_MESSAGING: '/help/secure-messages',
    SECURE_MESSSAGING_SUCCESS: '/help/secure-messages/success',
    SELECT_PAYMENT: '/payments/select-payment',
    MAKE_PAYMENT: '/payments/make-payment',
    MAKE_PAYMENT_ERROR: '/payment-error',
    STATEMENTS: '/statements',
    TIMEOUT: '/timeout',
    UNAUTHENTICATED_ERROR: '/system-error',
};

export const ORCH_PATHS = {
    AT_A_GLANCE: '/ataglance',
    BASIC_PROFILE: '/profile/basic',
    CLIP_OFFER: '/clip-offer',
    CLIP_REQUEST: '/clip-request',
    COMPLAINT_START: '/complaint/start',
    COMPLAINT_SUBMIT: '/complaint/submit',
    CONSENTS: '/consents',
    CURRENCY_CONVERT: '/convert-currency',
    DIRECT_DEBIT: '/directdebit',
    EDIT_EMAIL: '/userProfile/editEmail',
    LOGOUT: '/logout',
    HPP_PAYMENT: '/payment/create',
    HPP_PAYMENT_STATUS: '/payment/status',
    PAYMENT_INFO: '/paymentinfo',
    PREFERENCES: '/preferences',
    SECURE_MESSAGE_SUBMIT: '/securemessage/submit',
    SESSION: '/session',
    STATEMENTS_CREDIT_CYCLES: '/statements/creditcycles',
    STATEMENTS_LIST: '/statements/list',
    TRANSACTIONS_PENDING: '/transactions/pending',
    TRANSACTIONS_RECENT: '/transactions/recent',
    TRUSTPILOT: '/get-trustpilot',
    UPDATE_BALANCE_TRANSFER: '/update-balance-transfer',
    USER_PROFILE: '/userProfile',
};

export const NON_BRANDED_LINKS = {
    OUR_APP: 'https://www.capitalone.co.uk/creditcards/our-mobile-app',
    COST_OF_LIVING: 'https://www.capitalone.co.uk/support/cost-of-living-support',
    GET_IN_TOUCH: 'https://www.capitalone.co.uk/support/contact-us',
    PAYMENTS_TEL_NUMBER: '03444&nbsp;812&nbsp;812',
    SIGN_IN_PAGE: 'https://www.capitalone.co.uk/creditcards/sign-in',
};

export const BRANDED_LINKS = {
    POST_OFFICE: 'https://www.postoffice.co.uk/credit-card',
    ASOS: 'https://www.asos.com/discover/asos-credit-card',
};

export enum HttpCodes {
    SUCCESS = 200,
    FORBIDDEN = 403,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _BRAND_TELEPHONE_NUMBERS = {
    CapitalOne: '03444 812 812',
    Thinkmoney: '03444 812 812',
    Ocean: '03444 812 812',
    Luma: '0333 000 0477',
    ASOS: '03444 815 825',
    PostOffice: '03444 810 902',
    Very: '0333 000 0064',
    Littlewoods: '0333 000 0606',
};

export const BRAND_TELEPHONE_NUMBERS = convertToLenientBrandMap(_BRAND_TELEPHONE_NUMBERS);
