import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ORCH_PATHS, PATHS } from '../../utils/constants';
import orchestration, { controller } from '../../utils/orchestration';
import reportError, { createError } from '../../utils/reportError';

/**
 * call logout endpoint on orch
 * abort all other in-flight requests
 * directly navigate the browser to the logout page (this will also clear any local caches)
 */
const useLogout = (path = PATHS.LOGOUT) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation({
        mutationKey: ['logout'],
        mutationFn: async () => {
            try {
                await orchestration.post(ORCH_PATHS.LOGOUT);
                /* The session-storage is automatically cleared upon closing tab/window. 
                But we need the session-storage to be cleared upon logout. This is to protect 
                users on a public computer  
                */
                sessionStorage.removeItem('previousPayment');
                controller.abort('logout');
                navigate(path);
                await queryClient.clear();
            } catch (cause) {
                reportError(createError(cause, { path: ORCH_PATHS.LOGOUT }));
                navigate(PATHS.AUTHENTICATED_ERROR);
            }
        },
    });
};

export default useLogout;
