import balanceTransfer from './balanceTransfer';
import complaints from './complaints';
import header from './header';
import iconButton from './iconButton';
import secondaryNav from './secondaryNav';
import transactions from './transactions';

export default {
    balanceTransfer,
    complaints,
    header,
    secondaryNav,
    iconButton,
    transactions,
};
