import { useState } from 'react';

import { Box, Heading, InfoIcon, InfoPanel, Modal, Paragraph, Text } from '@cof/plastic-components';

import { DataRow } from '../../atoms/DataRow/DataRow';
import DataTable from '../../atoms/DataTable/DataTable';
import { FlexWithBrandGrey } from '../../atoms/Flex/Flex';
import IconButton from '../../atoms/IconButton/IconButton';
import MakeAPaymentButton from '../../atoms/MakeAPaymentButton/MakeAPaymentButton';
import RouterLink from '../../atoms/RouterLink/RouterLink';
import { useAtAGlanceData } from '../../data/atAGlance';
import { useDirectDebitData } from '../../data/directDebit';
import { PATHS } from '../../utils/constants';
import { formatCurrencyValue } from '../../utils/formatters';

const DateFormatter = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', timeZone: 'UTC' });

type StatementSummaryProps = {
    column?: 'one' | 'two';
};

export const StatementSummary: React.FC<StatementSummaryProps> = ({ column }) => {
    const { data, isPending, isError } = useAtAGlanceData();
    const {
        data: directDebitData,
        isPending: isDirectDebitLoading,
        isError: isDirectDebitError,
    } = useDirectDebitData();

    const [isAmountInformationModalOpen, setAmountInformationModalOpen] = useState(false);
    const [isPaymentDueModalOpen, setPaymentDueModalOpen] = useState(false);

    return (
        <Box ml={column === 'two' ? [null, null, 'sm'] : null} mr={column === 'one' ? [null, null, 'sm'] : null}>
            <Heading data-qa-id="statement-summary-heading" level="2" size="small" textAlign="left" mb="xs">
                Statement summary
            </Heading>

            {isError || isDirectDebitError ? (
                <>
                    <InfoPanel title="Sorry!" type="error">
                        <Text data-qa-id="summary-error-text">
                            Something went wrong trying to fetch your data. Please try again later.
                        </Text>
                    </InfoPanel>
                </>
            ) : (
                <DataTable>
                    <DataRow
                        Left={<Text textAlign="left">Your payment due is</Text>}
                        Right={
                            <>
                                {!isPending && data ? (
                                    <IconButton
                                        onClick={() => setAmountInformationModalOpen(true)}
                                        Icon={InfoIcon}
                                        data-qa-id="statement-min-payment"
                                    >
                                        <span data-qa-id="statement-min-payment-amount">
                                            {formatCurrencyValue(data?.statement.paymentDueAmount.amount)}
                                        </span>
                                    </IconButton>
                                ) : (
                                    <Text data-qa-id="statement-min-payment" size="large" fontWeight="medium">
                                        ...
                                    </Text>
                                )}
                            </>
                        }
                    />
                    <DataRow
                        Left={<Text textAlign="left">Statement balance</Text>}
                        Right={
                            <Text data-qa-id="statement-balance" fontWeight="medium" size="large" textAlign="right">
                                {!isPending && data ? formatCurrencyValue(data?.statement.balance.amount) : '...'}
                            </Text>
                        }
                    />
                    <DataRow
                        Left={<Text textAlign="left">Next payment due by</Text>}
                        Right={
                            <>
                                {isPending ? (
                                    <Text data-qa-id="statement-next-payment" fontWeight="medium">
                                        ...
                                    </Text>
                                ) : data?.statement.nextPaymentDueBy ? (
                                    <IconButton
                                        onClick={() => setPaymentDueModalOpen(true)}
                                        Icon={InfoIcon}
                                        data-qa-id="statement-next-payment"
                                    >
                                        <span>{DateFormatter.format(new Date(data?.statement.nextPaymentDueBy))}</span>
                                    </IconButton>
                                ) : (
                                    <Text data-qa-id="statement-next-payment" fontWeight="medium">
                                        -
                                    </Text>
                                )}
                            </>
                        }
                    />

                    {!isPending && !isDirectDebitLoading && directDebitData?.showDirectDebit && (
                        <DataRow
                            flexWrap="wrap"
                            Left={
                                !directDebitData.isSetupNearDueDate && <Text textAlign="left">Your Direct Debit</Text>
                            }
                            Right={
                                <>
                                    {directDebitData.isSetupNearDueDate ? (
                                        <InfoPanel type="info">
                                            <Text data-qa-id="direct-debit-info-box">
                                                Your Direct Debit will not come into effect until your next statement.
                                                This statement, you will need to make a payment if you have a balance
                                                due.
                                            </Text>
                                        </InfoPanel>
                                    ) : !directDebitData.cycle.paymentTakeDate ? (
                                        <RouterLink to={PATHS.MY_CARD} data-qa-id="direct-debit-my-card-link">
                                            View Direct Debit details
                                        </RouterLink>
                                    ) : (
                                        <Text fontWeight="medium" data-qa-id="direct-debit-claim-date">
                                            Comes out on or after{' '}
                                            {DateFormatter.format(new Date(directDebitData.cycle.paymentTakeDate))}
                                        </Text>
                                    )}
                                </>
                            }
                        />
                    )}
                </DataTable>
            )}

            {/* MODALS */}

            <Modal
                id="amount-information"
                heading="Amount information"
                isOpen={isAmountInformationModalOpen}
                onClose={() => setAmountInformationModalOpen(false)}
                rootId="root"
            >
                <FlexWithBrandGrey flexDirection="column" width={1}>
                    <FlexWithBrandGrey
                        py="sm"
                        justifyContent="space-between"
                        mt="0.3rem"
                        borderBottom="3px solid"
                        borderColor="brand.grey50"
                    >
                        <Text textAlign="left">Overlimit amount</Text>
                        <Text data-qa-id="statement-over-limit-amount" fontWeight="medium" textAlign="right">
                            {!isPending && data ? formatCurrencyValue(data?.statement.overlimitAmount.amount) : '...'}
                        </Text>
                    </FlexWithBrandGrey>

                    <FlexWithBrandGrey
                        py="sm"
                        mt="0.3rem"
                        borderBottom="3px solid"
                        borderColor="brand.grey50"
                        flexDirection="column"
                    >
                        <FlexWithBrandGrey justifyContent="space-between" mb="md">
                            <Text textAlign="left">Missed payments</Text>
                            <Text data-qa-id="statement-missed-payment-amount" fontWeight="medium" textAlign="right">
                                {!isPending && data
                                    ? formatCurrencyValue(data?.statement.missedPayments.amount)
                                    : '...'}
                            </Text>
                        </FlexWithBrandGrey>

                        <Box>
                            <InfoPanel type="info" backgroundColor="global.white" p={0}>
                                <Paragraph padding={0}>
                                    The higher of your missed payments or overlimit amount is due now.
                                </Paragraph>
                            </InfoPanel>
                        </Box>
                    </FlexWithBrandGrey>

                    <FlexWithBrandGrey py="sm" justifyContent="space-between" mt="0.3rem">
                        <Text textAlign="left">Minimum payment</Text>
                        <Text data-qa-id="statement-minimum-payment-amount" fontWeight="medium" textAlign="right">
                            {!isPending && data ? formatCurrencyValue(data?.statement.minimumPayment.amount) : '...'}
                        </Text>
                    </FlexWithBrandGrey>

                    <FlexWithBrandGrey py="md" justifyContent="center">
                        <MakeAPaymentButton />
                    </FlexWithBrandGrey>

                    <InfoPanel type="info" backgroundColor="global.white" p={0}>
                        <Paragraph>
                            If your overlimit amount is more than your missed payments amount, paying your overlimit
                            amount will reduce (and may clear) the minimum payment due.
                        </Paragraph>
                    </InfoPanel>
                </FlexWithBrandGrey>
            </Modal>

            <Modal
                id="payment-due"
                heading="Date payment due"
                isOpen={isPaymentDueModalOpen}
                onClose={() => setPaymentDueModalOpen(false)}
                rootId="root"
            >
                <Paragraph>
                    You must pay us at least your minimum payment by this date. Paying on time is really important, to
                    avoid late payment fees and extra interest charges. A payment can take up to 5 working days to reach
                    us and be processed, so please plan for this. If you have an overlimit amount or any missed
                    payments, please note the higher of these amounts is due now.
                </Paragraph>
            </Modal>
        </Box>
    );
};
