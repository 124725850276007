import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import useLogout from '../../data/mutators/logout';
import useSessionRefresh from '../../data/mutators/session';

// only run the onActive function every 30sec max
export const SESSION_API_CALL_THROTTLE = 30 * 1000;

const useSession = (idleTimeout: number) => {
    const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
    const { mutate: refreshSession } = useSessionRefresh();
    const { mutate: logout } = useLogout();

    // timeout prompt modal should appear 60sec before you time out
    const modalTimeout = 60 * 1000;

    const onPrompt = () => {
        // all other session events are disabled while prompt is active
        setIsIdleModalOpen(true);
    };
    const onIdle = () => {
        setIsIdleModalOpen(false);
        logout();
    };
    const onActive = () => {
        // Called when user becomes active after a period of being idle
        setIsIdleModalOpen(false);
        refreshSession();
    };
    const onAction = () => {
        // Called while use is active (throttled to, at most, every 30secs)
        if (!isIdleModalOpen) {
            refreshSession();
        }
    };
    const getOptimalModalTimeoutValue = (): number => {
        /**
         * to prevent modalTimeout from being greater than idleTimeout, we set
         * modalTimeout to idletimeout - 1.
         */
        const timeDifference: number = modalTimeout - idleTimeout;

        if (timeDifference >= 0) return idleTimeout - 1;
        return modalTimeout;
    };

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: getOptimalModalTimeoutValue(),
        throttle: SESSION_API_CALL_THROTTLE,
        onPrompt: onPrompt,
        onIdle: onIdle,
        onActive: onActive,
        onAction: onAction,
    });

    return { isIdleModalOpen, ...idleTimer };
};

export default useSession;
