import { useSearchParams } from 'react-router-dom';

import { REDIRECT_URI_KEY, addRedirectURI } from '../../hooks/useRedirectURI';
import { BRANDED_LINKS, NON_BRANDED_LINKS } from '../../utils/constants';

const redirectToBrandPage = (brand: string | null) => {
    if (brand === 'postoffice') {
        window.location.href = BRANDED_LINKS.POST_OFFICE;
    } else if (brand === 'asos') {
        window.location.href = BRANDED_LINKS.ASOS;
    } else {
        window.location.href = NON_BRANDED_LINKS.SIGN_IN_PAGE;
    }
};

export const DeepLinking = () => {
    const [params] = useSearchParams();
    const redirectUriParams = params.get(REDIRECT_URI_KEY);
    const brand = params.get('brand');
    if (redirectUriParams) {
        addRedirectURI(redirectUriParams);
    }
    redirectToBrandPage(brand);
    return null;
};
