import { isValid } from 'date-fns';

export const formatCurrencyValue = (value?: number | string, isPence = false) => {
    let valueInPounds = typeof value === 'string' ? parseFloat(value) : value;

    if (isPence && valueInPounds) {
        valueInPounds = valueInPounds / 100;
    }

    return valueInPounds?.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2 });
};

export const titleCase = (name: string | undefined) =>
    typeof name === 'string'
        ? name.toLowerCase().replace(/\b([a-z])/g, (character) => character.toUpperCase())
        : undefined;

// Returns a string in the format: "5 December 2019"
export const formatDate = (date: Date | null | undefined) => {
    return date && isValid(date)
        ? new Intl.DateTimeFormat('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
          }).format(date)
        : '';
};
