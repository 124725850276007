import { useLocation } from 'react-router-dom';

import { BRANDS, Box, useBrand } from '@cof/plastic-components';

import { PATHS } from '../../utils/constants';

const Divider = () => {
    const { pathname } = useLocation();
    const brand = useBrand();

    // Display the divider on the ASOS pages that use the MoneyWorriesBanner
    return (
        <>
            {brand === BRANDS.ASOS && (pathname === PATHS.MY_CARD || pathname === PATHS.MANAGE_DD) && (
                <Box
                    data-qa-id="divider"
                    borderBottomColor="asos.grey850"
                    borderBottomWidth="0.3rem"
                    borderBottomStyle="solid"
                />
            )}
        </>
    );
};

export default Divider;
