import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Box, BrandedHeader, Heading, PageRowFullHeight, THEME_CONSTANTS } from '@cof/plastic-components';

import { BrandedLogo } from '../../molecules/Header/Header';

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

export const ErrorPageHeader = () => <BrandedHeader maxWidth={SCREEN_WIDTHS.TEN_COLUMN} bordered Logo={BrandedLogo} />;

export default ({ headingText, children }: { headingText: string; children: ReactNode }) => {
    const theme = useTheme();
    return (
        <PageRowFullHeight maxWidth={SCREEN_WIDTHS.TEN_COLUMN} wrapperBg={theme.componentColors.pageHeader.row}>
            <Heading
                mt="lg"
                level="1"
                color={theme.componentColors.pageHeader.text}
                textAlign="left"
                data-qa-id="header-error-page"
            >
                {headingText}
            </Heading>
            <Box mt="sm" data-qa-id="body-error-page">
                {children}
            </Box>
        </PageRowFullHeight>
    );
};
