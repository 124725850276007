/* c8 ignore start */
import { BRANDS, Markdown, Paragraph, useBrand } from '@cof/plastic-components';

import useBrandedLinks from '../../../hooks/useBrandedLinks';
import { NON_BRANDED_LINKS, PATHS } from '../../../utils/constants';
import ErrorPage from '../ErrorPageTemplate';

export default ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const brand = useBrand();
    const brandedLinks = useBrandedLinks();
    const textVariant = brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark';

    const pageContent = `
**Here are some simple steps you can take:**
* Return to your [At a glance](${PATHS.AT_A_GLANCE}) and try again${
        isAuthenticated ? `, or follow another link in the navigation at the top of the page` : ''
    }.
* You can use [our app](${NON_BRANDED_LINKS.OUR_APP}){target="_blank"} to view your balance and make payments.
* If you need help quickly, [give us a call](${brandedLinks['contact-us']}){target="_blank"}.
`;

    return (
        <ErrorPage headingText="Oops! Something went wrong">
            <Paragraph mb="lg" size="large" variant={textVariant}>
                Let's get you back on track
            </Paragraph>
            <Markdown variant={textVariant}>{pageContent}</Markdown>
        </ErrorPage>
    );
};
