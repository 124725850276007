import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { ORCH_PATHS } from '../../utils/constants';
import orchestration from '../../utils/orchestration';
import reportError, { createError } from '../../utils/reportError';

const useSessionRefresh = (opts?: Partial<UseMutationOptions>) => {
    const isRunningInCypress = !!window.Cypress;
    return useMutation({
        mutationKey: ['session-refresh'],
        mutationFn: async () => {
            const path = ORCH_PATHS.SESSION;
            const { data, status } = await orchestration.post(path, null, {
                validateStatus: (validationStatus) => validationStatus !== 403,
            });
            // Manually reporting unexpected error responses from /session refresh as these are ignored/suppressed otherwise
            if (status !== 200) {
                reportError(createError('/session refresh error', { path, status }));
            }
            return data;
        },
        retry: isRunningInCypress ? false : 3,
        meta: {
            shouldReportError: false,
        },
        ...opts,
    });
};

export default useSessionRefresh;
