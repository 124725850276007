import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';
import { currencyValueSchema, statementSchema } from './common';

export enum BannerState {
    AMOUNT_CORRECTION = 'AMOUNT_CORRECTION',
    BT_TOO_HIGH_CALL_US = 'BT_TOO_HIGH_CALL_US',
    CALL_US = 'CALL_US',
}

const balanceTransferSchema = z.discriminatedUnion('eligible', [
    z.object({ eligible: z.literal(false) }),
    z.object({
        eligible: z.literal(true),
        totalValue: z.string(),
        balanceTransfers: z.array(
            z.object({
                id: z.string(),
                clientTime: z.string(),
                monetaryAmount: z.object({
                    currency: z.string(),
                    value: z.string(),
                }),
                obfuscatedNumber: z.string(),
            }),
        ),
        terms: z.array(
            z.object({
                endDate: z.string(),
                rate: z.string(),
                fee: z.object({
                    percentage: z.string(),
                }),
                type: z.string(),
                status: z.string(),
            }),
        ),
        minimumAmount: z.string(),
        maximumAmount: z.string(),
        bannerState: z.nativeEnum(BannerState),
    }),
]);

export const atAGlanceValidator = z.object({
    creditLimit: currencyValueSchema,
    creditAvailable: currencyValueSchema,
    currentBalance: currencyValueSchema,
    statement: statementSchema,
    contextualMessages: z.array(z.string()),
    cardNumber: z.string(),
    cardType: z.string(),
    brand: z.string(),
    plasticId: z.string().nullable().optional(),
    balanceTransfer: balanceTransferSchema,
    uci: z.string().optional(),
});

export type AtAGlanceResponseData = z.infer<typeof atAGlanceValidator>;

export const useAtAGlanceData = (opts?: { enabled: boolean }) => {
    const isRunningInCypress = !!window.Cypress;
    return useQuery({
        queryKey: ['at-a-glance'],
        queryFn: async () => {
            const path = ORCH_PATHS.AT_A_GLANCE;
            try {
                const response = await orchestration.get(path);
                const atAGlanceResponseBody = atAGlanceValidator.parse(response.data);
                if (atAGlanceResponseBody.uci && window.newrelic && window.newrelic.setUserId) {
                    window.newrelic.setUserId(atAGlanceResponseBody.uci);
                }
                return atAGlanceResponseBody;
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
        // cache /at-a-glance data for at least 1 minute
        staleTime: isRunningInCypress ? 0 : 60 * 1000,
        ...opts,
    });
};
