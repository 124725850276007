import { sharedTheme } from '@cof/plastic-components';

import { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { global, ocean },
} = sharedTheme;

const paymentModal = {
    protectionFinanceIcon: ocean.blue450,
};

const header = {
    config: {
        activeLinkBorder: '-45px',
    },
    background: {
        mobile: global.white,
        desktop: ocean.blue100,
    },
    border: {
        mobile: ocean.blue100,
        desktop: ocean.blue800,
    },
    text: {
        desktop: ocean.blue800,
        mobile: ocean.teal450,
    },
    helpIcon: ocean.blue450,
    link: {
        border: {
            mobile: ocean.blue100,
        },
        color: {
            mobile: ocean.blue800,
            desktop: ocean.blue800,
        },
        active: {
            mobile: ocean.blue800,
            desktop: ocean.blue800,
            border: ocean.blue800,
        },
        background: {
            mobile: global.transparent,
            desktop: global.transparent,
            active: {
                mobile: ocean.blue100,
                desktop: global.transparent,
                active: ocean.red550,
                border: global.white,
            },
        },
    },
    icon: {
        active: ocean.blue450,
        inactive: 'currentColor',
    },
    navBar: {
        border: {
            tablet: ocean.blue800,
        },
        background: {
            mobile: global.transparent,
            desktop: ocean.blue100,
        },
    },
    ///
};

const balanceTransfer = { icon: ocean.blue450, iconOnBtPage: ocean.blue450 };

const currencyConverter = {
    heading: {
        background: ocean.blue100,
        text: ocean.blue800,
    },
    result: ocean.blue450,
};

const pageHeader = {
    row: ocean.blue100,
    text: ocean.blue800,
    emergencyBanner: {
        background: ocean.blue450,
    },
};

const permissions = {
    permissionCard: {
        active: ocean.green650,
        inactive: ocean.red550,
    },
    endDateBox: {
        active: ocean.blue100,
        inactive: ocean.red550,
    },
    accountPermissions: {
        icon: ocean.blue450,
    },
};

const secondaryNav = {
    badge: {
        color: global.white,
        backgroundColor: ocean.blue450,
    },
    button: {
        default: {
            mobile: ocean.blue800,
            mobileIcon: ocean.blue450,
            desktop: ocean.blue800,
            desktopIcon: ocean.blue450,
        },
        expanded: ocean.blue800,
        expandedIcon: ocean.blue450,

        backgroundColor: global.white,
    },
    contentWrapper: {
        backgroundColor: global.white,
        border: global.white,
    },
};

const secureMessages = {
    noMessagesIcon: ocean.blue450,
    emailIcon: ocean.blue450,
    agent: {
        text: ocean.blue800,
        background: global.grey50,
    },
    infoIcon: { 1: ocean.orange450, 2: ocean.blue800 },
    customer: {
        background: ocean.blue750,
        text: global.white,
    },
    messageHistoryBackground: global.grey50,
    tableDivider: global.grey200,
};

const transactions = {
    text: {
        standard: ocean.blue800,
        link: ocean.blue450,
        credit: ocean.green650,
        overlimit: ocean.red550,
        debit: ocean.red550,
    },
    flag: {
        link: ocean.blue450,
        credit: ocean.green650,
        standard: global.transparent,
        overlimit: ocean.red550,
        debit: ocean.red550,
    },
    icon: ocean.blue450,
};

const button = {
    textLink: {
        text: ocean.blue800,
        icon: ocean.blue450,
        hoverText: ocean.blue800,
        hoverIcon: 'rgb(20, 65, 190)', // this is a darkened version of ocean.blue450
    },
};

const accordion = {
    background: ocean.grey50,
    border: ocean.grey50,
    wrapper: {
        dark: ocean.grey50, //Plastic default is global.grey50. Remove this when plastic is updated
    },
};

const box = {
    background: ocean.grey50,
    border: ocean.grey50,
};

const infoPanel = {
    background: ocean.grey50,
};

const dataRow = {
    dark: {
        background: ocean.grey50,
    },
    light: {
        background: global.white,
    },
};

const defaultExport: ComponentColors = {
    accordion,
    box,
    infoPanel,
    balanceTransfer,
    button,
    currencyConverter,
    dataRow,
    header,
    pageHeader,
    paymentModal,
    permissions,
    secondaryNav,
    secureMessages,
    transactions,
};

export default defaultExport;
