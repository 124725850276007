/* c8 ignore start */
import { BRANDS, Link, Paragraph, useBrand } from '@cof/plastic-components';

import ErrorPage from '../ErrorPageTemplate';

export default () => {
    const brand = useBrand();
    const textVariant = brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark';
    return (
        <ErrorPage headingText="Your online account access has been changed">
            <Paragraph mb="sm" size="large" variant={textVariant}>
                Please call us to discuss your account
            </Paragraph>
            <Paragraph variant={textVariant} p={0}>
                If you’re in the UK, call us on{' '}
                <Link variant={textVariant} href="tel:08009524966">
                    0800 952 4966
                </Link>
                . If you’re outside the UK, please dial{' '}
                <Link variant={textVariant} href="tel:+441159938002">
                    +44 115 993 8002
                </Link>
                .
            </Paragraph>
            <Paragraph variant={textVariant} p={0}>
                Calls may be monitored or recorded for training purposes.
            </Paragraph>
        </ErrorPage>
    );
};
