import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

export default {
    button: ({ theme }: { theme: DefaultTheme }) => css`
        svg {
            color: ${theme.componentColors.button.textLink.icon ??
            theme.componentColors.button.textLink.text ??
            'currentColor'};
        }

        &:hover {
            svg {
                color: ${theme.componentColors.button.textLink.hoverIcon ??
                theme.componentColors.button.textLink.hoverText ??
                'currentColor'};
            }
        }
    `,
};
