import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Flex, PageRow, THEME_CONSTANTS, TwoColumn } from '@cof/plastic-components';

import MakeAPaymentButton from '../../atoms/MakeAPaymentButton/MakeAPaymentButton';
import RouterLink from '../../atoms/RouterLink';
import TrustpilotBanner from '../../atoms/TrustpilotBanner/TrustpilotBanner';
import { useAtAGlanceData } from '../../data/atAGlance';
import { StatementSummary } from '../../molecules/StatementSummary/StatementSummary';
import { PATHS } from '../../utils/constants';
import { Transactions } from './Transactions';
import AccountSummary from './components/AccountSummary';
import AtAGlancePageHeader from './components/AtAGlancePageHeader';
import BalanceTransferBanner from './components/BalanceTransferBanner';

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

const AtAGlance = () => {
    const { isError } = useAtAGlanceData();
    const navigate = useNavigate();

    useEffect(() => {
        if (isError) {
            navigate(PATHS.AUTHENTICATED_ERROR);
        }
    }, [isError, navigate]);
    return (
        <>
            <AtAGlancePageHeader />
            <PageRow maxWidth={SCREEN_WIDTHS.TEN_COLUMN}>
                <Box>
                    <TwoColumn
                        splitOn="tablet"
                        LeftContent={<AccountSummary column="one" />}
                        RightContent={
                            <>
                                <StatementSummary column="two" />
                                <Flex mt="md" flexDirection="column" alignItems="flex-end">
                                    <MakeAPaymentButton mb="sm" />
                                    <RouterLink data-qa-id="view-statements" to={`${PATHS.STATEMENTS}`}>
                                        View statement
                                    </RouterLink>
                                </Flex>
                            </>
                        }
                    />
                </Box>
                <BalanceTransferBanner />
                <TrustpilotBanner />
                <Transactions />
                <Flex justifyContent="center" mt="lg">
                    <Button
                        as={Link}
                        /* @ts-ignore: ignores react-router 'to' prop which doesn't exist on Button */
                        to="/help/questions?category=pinned#unrecognised-transactions"
                        data-qa-id="problem-button"
                        variant="secondary"
                    >
                        Notice a problem?
                    </Button>
                </Flex>
            </PageRow>
        </>
    );
};

export default AtAGlance;
