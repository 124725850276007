import { BRANDS, footerContent, useBrand } from '@cof/plastic-components';

type AllowedLinks = 'legal' | 'cookies' | 'terms-of-use' | 'contact-us' | 'privacy';
type BrandedLinks = Record<AllowedLinks, string>;

/**
 * Custom hook for getting the current brands links
 *
 * Defaults to use Capital One links if brand does not exist
 */
export default function useBrandedLinks() {
    const brand = useBrand();
    const { links: brandedLinks } = footerContent[brand] || footerContent[BRANDS.CAPITAL_ONE]!;

    return brandedLinks.reduce((acc, { text, url }) => {
        const id = text.replace(/\W/g, '-').toLowerCase();

        return {
            ...acc,
            [id]: url,
        };
    }, {}) as BrandedLinks;
}
