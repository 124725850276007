/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { css, styled } from 'styled-components';

import { Box, Button, Flex, Heading, InfoIcon, Modal, Paragraph, Text } from '@cof/plastic-components';

import { DataRow as BorderlessDataRow } from '../../../atoms/DataRow/DataRow';
import DataTable from '../../../atoms/DataTable/DataTable';
import IconButton from '../../../atoms/IconButton/IconButton';
import { InfoPanelWithBrandGrey } from '../../../atoms/InfoPanel/InfoPanel';
import { useAtAGlanceData } from '../../../data/atAGlance';
import { usePendingTransactionsData } from '../../../data/transactions';
import { useBrandErrorColor } from '../../../utils/brand';
import { formatCurrencyValue } from '../../../utils/formatters';
import ClipOfferBanner from './ClipOfferBanner';

const DataRow = styled(BorderlessDataRow)`
    ${({ theme, hasError = false }: { theme: any; hasError?: boolean }) => css`
        ${hasError ? `border-left: 0.3rem solid ${theme.colors[theme.id].red550}` : ''};
    `}
`;

export default ({ column }: { column: 'one' | 'two' }) => {
    const { data: atAGlanceData } = useAtAGlanceData();
    const [isAvailableToSpendModalOpen, setAvailableToSpendModalOpen] = useState(false);
    const [isPendingTransactionsModalOpen, setPendingTransactionsModalOpen] = useState(false);

    const pendingTransactions = usePendingTransactionsData();
    const displayPendingTransactions =
        !pendingTransactions.isError && pendingTransactions.data && pendingTransactions.data?.total.amount > 0;

    const isOverLimit = atAGlanceData && atAGlanceData?.currentBalance.amount > atAGlanceData?.creditLimit.amount;
    const errorColor = useBrandErrorColor();

    return (
        <Box ml={column === 'two' ? [null, null, 'sm'] : null} mr={column === 'one' ? [null, null, 'sm'] : null}>
            <Flex alignItems="baseline" justifyContent="space-between">
                <Heading level="2" size="small" textAlign="left" mb="xs">
                    Account summary
                </Heading>
                {atAGlanceData?.cardNumber && (
                    <Box>
                        <Text>Card ending: </Text>
                        <Text data-qa-id="last-four-digits" fontWeight="semiBold">
                            {atAGlanceData.cardNumber}
                        </Text>
                    </Box>
                )}
            </Flex>
            <DataTable variant="dark">
                <DataRow
                    Left={<Text textAlign="left">Available to spend</Text>}
                    Right={
                        <IconButton
                            onClick={() => setAvailableToSpendModalOpen(true)}
                            Icon={InfoIcon}
                            data-qa-id="available-credit"
                        >
                            <span>{formatCurrencyValue(atAGlanceData?.creditAvailable.amount) ?? '...'}</span>
                        </IconButton>
                    }
                />
                <DataRow
                    data-qa-id="current-balance-row"
                    Left={<Text textAlign="left">Current balance</Text>}
                    Right={
                        <Text
                            data-qa-id="current-balance"
                            fontWeight="medium"
                            size="large"
                            textAlign="right"
                            color={isOverLimit ? errorColor : undefined}
                        >
                            {formatCurrencyValue(atAGlanceData?.currentBalance.amount) ?? '...'}
                        </Text>
                    }
                    hasError={isOverLimit}
                />
                <DataRow
                    Left={<Text textAlign="left">Credit limit</Text>}
                    Right={
                        <Text data-qa-id="credit-limit" fontWeight="medium" size="large" textAlign="right">
                            {formatCurrencyValue(atAGlanceData?.creditLimit.amount) ?? '...'}
                        </Text>
                    }
                />
                <ClipOfferBanner />
            </DataTable>

            {displayPendingTransactions && (
                <InfoPanelWithBrandGrey
                    type="info"
                    Icon={InfoIcon}
                    bg="global.white"
                    border="0.3rem solid"
                    borderColor="brand.grey50"
                >
                    <Text>
                        You have pending transactions of{' '}
                        <Button
                            onClick={() => setPendingTransactionsModalOpen(true)}
                            variant="textLinkInline"
                            data-qa-id="pending-transactions"
                        >
                            {formatCurrencyValue(pendingTransactions?.data?.total?.amount) ?? '...'}
                        </Button>
                    </Text>
                </InfoPanelWithBrandGrey>
            )}

            <Modal
                id="available-to-spend"
                heading="Available to spend"
                isOpen={isAvailableToSpendModalOpen}
                onClose={() => setAvailableToSpendModalOpen(false)}
                rootId="root"
            >
                <Paragraph>
                    This is the amount you can spend today. It includes pending transactions, but doesn't include any
                    fees or interest due to be applied to your account before your next statement. Please be aware that
                    if recent Direct Debits and other payments to your credit card are reversed, because you didn't have
                    enough money in your bank account to cover them, your 'available to spend' amount will decrease.
                </Paragraph>
            </Modal>

            <Modal
                id="pending-transactions"
                heading="Pending transactions"
                isOpen={isPendingTransactionsModalOpen}
                onClose={() => setPendingTransactionsModalOpen(false)}
                rootId="root"
            >
                <Paragraph>
                    Any spending, bill payments and refunds that haven't been fully processed yet. Some transactions can
                    take up to 10 working days to clear. While they're pending, we do include them in what you have
                    'available to spend', but we don't include them in your account balance. You can find details of
                    what's pending in your transaction history. Once processed, they'll appear on your statement.
                </Paragraph>
            </Modal>
        </Box>
    );
};
