import type { DefaultTheme } from 'styled-components';

import { BRANDS, createTheme, themes } from '@cof/plastic-components';

import appAsosTheme from './asos';
import appCapitalOneTheme from './capitalOne';
import appLittlewoodsTheme from './littlewoods';
import appLumaTheme from './luma';
import appOceanTheme from './ocean';
import appPostOfficeTheme from './postOffice';
import appThinkMoneyTheme from './thinkMoney';
import appVeryTheme from './very';

type AllThemes = Record<string, { default: DefaultTheme }>;

const {
    asosTheme: plasticAsosTheme,
    capitalOneTheme: plasticCapitalOneTheme,
    oceanTheme: plasticOceanTheme,
    littlewoodsTheme: plasticLittlewoodsTheme,
    lumaTheme: plasticLumaTheme,
    postOfficeTheme: plasticPostOfficeTheme,
    thinkMoneyTheme: plasticThinkMoneyTheme,
    veryTheme: plasticVeryTheme,
} = themes;

const allThemes: AllThemes = {
    [BRANDS.CAPITAL_ONE]: { default: createTheme(appCapitalOneTheme, plasticCapitalOneTheme) },
    [BRANDS.LITTLEWOODS]: {
        default: createTheme(createTheme(appLittlewoodsTheme, appCapitalOneTheme), plasticLittlewoodsTheme),
    },
    [BRANDS.LUMA]: { default: createTheme(createTheme(appLumaTheme, appCapitalOneTheme), plasticLumaTheme) },
    [BRANDS.OCEAN]: { default: createTheme(createTheme(appOceanTheme, appCapitalOneTheme), plasticOceanTheme) },
    [BRANDS.POST_OFFICE]: {
        default: createTheme(createTheme(appPostOfficeTheme, appCapitalOneTheme), plasticPostOfficeTheme),
    },
    [BRANDS.THINK_MONEY]: {
        default: createTheme(createTheme(appThinkMoneyTheme, appCapitalOneTheme), plasticThinkMoneyTheme),
    },
    [BRANDS.VERY]: { default: createTheme(createTheme(appVeryTheme, appCapitalOneTheme), plasticVeryTheme) },
    [BRANDS.ASOS]: { default: createTheme(createTheme(appAsosTheme, appCapitalOneTheme), plasticAsosTheme) },
};

export default allThemes;
