import { Flex, Heading, OneColumn } from '@cof/plastic-components';

import RouterLink from '../../atoms/RouterLink/RouterLink';
import { PATHS } from '../../utils/constants';
import { PendingTransactions } from './components/PendingTransactions';
import { RecentTransactions } from './components/RecentTransactions';

export function Transactions() {
    return (
        <OneColumn
            Content={
                <>
                    <Flex alignItems="baseline" justifyContent="space-between">
                        <Heading level="2" size="small" textAlign="left" mb="xs">
                            Recent transactions
                        </Heading>
                        <RouterLink data-qa-id="download-transactions" to={`${PATHS.STATEMENTS}#downloadTransactions`}>
                            Download Transactions
                        </RouterLink>
                    </Flex>
                    <PendingTransactions />
                    <RecentTransactions />
                </>
            }
        />
    );
}
