import { useEffect, useState } from 'react';

import { Button, Flex, Modal, Paragraph, Text } from '@cof/plastic-components';

import useLogout from '../../data/mutators/logout';
import { useCreateOrGetSession } from '../../data/session';
import useSession from './useSession';

const getTimeLeftSec = (timeLeftMs: number) => Math.ceil(timeLeftMs / 1000);

// 5 mins
const DEFAULT_SESSION_TIMEOUT = 300000;

export default () => {
    // get initial session details from orch
    const { data } = useCreateOrGetSession();

    const idleTimeoutMs = data ? data.timeToLive : DEFAULT_SESSION_TIMEOUT;
    const { isIdleModalOpen, activate, getRemainingTime, isPrompted } = useSession(idleTimeoutMs);
    const timeLeft = getTimeLeftSec(getRemainingTime());
    const [timeUntilLogout, setTimeUntilLogout] = useState(timeLeft);
    const { mutate: logout } = useLogout();

    const handleContinue = () => {
        activate();
    };

    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        // Declare timer that will store the remaining time until user is idle
        const timeLeftInterval = setInterval(() => {
            setTimeUntilLogout(Math.ceil(getRemainingTime() / 1000));
        }, 1000);
        return () => {
            clearInterval(timeLeftInterval);
        };
    }, [getRemainingTime, isPrompted]);

    return (
        <Modal
            isOpen={isIdleModalOpen}
            allowDismiss={false}
            rootId="root"
            heading="You've been inactive for a while"
            id="session"
        >
            <Paragraph>Because you've been inactive, you're about to be signed out.</Paragraph>
            <Paragraph>
                To carry on managing your account, select <Text fontWeight="medium">Continue</Text>. Or choose{' '}
                <Text fontWeight="medium">End session</Text> to safely sign out.
            </Paragraph>
            <Paragraph>
                If you do nothing, you will be logged out in <Text fontWeight="medium">{timeUntilLogout}</Text> seconds.
            </Paragraph>
            <Flex mt="md" flexDirection="column">
                <Button data-qa-id="continue-session-button" onClick={handleContinue}>
                    Continue
                </Button>
                <Button data-qa-id="end-session-button" mt="sm" variant="secondary" onClick={handleLogout}>
                    End session
                </Button>
            </Flex>
        </Modal>
    );
};
