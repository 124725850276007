import { useEffect, useState } from 'react';

export const FEATURE_FLAGS = {
    TRUST_PILOT_BANNER: 'trustPilotBanner',
};

export const useFeatureFlag = (feature: string) => {
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setFlag(window.config?.featureFlags?.[feature] ?? false);
    }, [feature]);

    return flag;
};
