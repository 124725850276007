/* c8 ignore start */
import { BRANDS, Paragraph, useBrand } from '@cof/plastic-components';

import RouterLink from '../../../atoms/RouterLink/RouterLink';
import { PATHS } from '../../../utils/constants';
import ErrorPage from '../ErrorPageTemplate';

export default () => {
    const brand = useBrand();
    const textVariant = brand === BRANDS.CAPITAL_ONE ? 'light' : 'dark';
    return (
        <ErrorPage headingText="Oops! Something went wrong">
            <Paragraph mb="sm" size="large" variant={textVariant}>
                Trying to sign in or register?
            </Paragraph>
            <Paragraph variant={textVariant}>
                It looks like there was an issue in trying to complete your request.
            </Paragraph>
            <Paragraph variant={textVariant}>
                Let's give it another go by{' '}
                <RouterLink data-qa-id="sign-in-link" variant={textVariant} to={PATHS.LOGIN}>
                    trying again
                </RouterLink>
                .
            </Paragraph>
        </ErrorPage>
    );
};
