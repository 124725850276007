import axios from 'axios';

import { HttpCodes, PATHS } from './constants';

const validResponse = (status: number) => status >= 200 && status < 400;

export const controller = new AbortController();
const csrfHeaderName = 'x-csrf-token';
const securityHeaders = {
    'X-Requested-With': 'XmlHttpRequest',
};

const makeCSRFHeader = () => {
    const csrfToken = window.localStorage.getItem(csrfHeaderName);
    return csrfToken
        ? {
              [csrfHeaderName]: csrfToken,
          }
        : {};
};
enum Methods {
    GET = 'get',
    POST = 'post',
    PATCH = 'patch',
    DELETE = 'delete',
}

const axiosInstance = axios.create({
    signal: controller.signal,
    baseURL: window.config.orchestration.url,
    timeout: window.config.orchestration.timeout,
    validateStatus: validResponse,
    withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
    const customHeaders = { 'ui-current-path': `${window.location.pathname}` };
    // Add security headers to request before it's sent
    const originalRequestHeaders = config.headers;
    config.headers =
        config.method?.toLowerCase() === Methods.GET
            ? {
                  ...originalRequestHeaders,
                  ...customHeaders,
                  ...securityHeaders,
              }
            : {
                  ...originalRequestHeaders,
                  ...customHeaders,
                  ...securityHeaders,
                  ...makeCSRFHeader(),
              };
    return config;
});

axiosInstance.interceptors.response.use(
    function (response) {
        // Set CSRF token in local storage when received from a GET request
        if (response.config?.method?.toLowerCase() === Methods.GET && response.headers[csrfHeaderName]) {
            window.localStorage.setItem(csrfHeaderName, response.headers[csrfHeaderName]);
        }

        return response;
    },
    function (error) {
        // 403s will redirect to timeout/login pages
        if (error.response?.status === HttpCodes.FORBIDDEN) {
            if (error.response.data?.reason === 'SESSION_EXPIRED') {
                window.location.assign(PATHS.TIMEOUT);
            } else {
                // Go to the login page, preserving the current query string
                const queryString = window.location.search ?? '';
                window.location.assign(`${PATHS.LOGIN}${queryString}`);
            }
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
