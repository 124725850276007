import cssSystem from '@styled-system/css';
import { MouseEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemContentWrapper,
    Box,
    Flex,
    Text,
} from '@cof/plastic-components';

import RouterLink from '../../atoms/RouterLink';
import { useBasicProfileData } from '../../data/basicProfile';
import useLogout from '../../data/mutators/logout';
import { PATHS } from '../../utils/constants';

const DesktopOnlyName = styled.span`
    ${cssSystem({ display: ['none', null, 'inline'] })}
`;

const AccountNameText = styled(Text)`
    ${cssSystem({ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' })}
`;

const AccountMenu = () => {
    const { status, data } = useBasicProfileData();
    return (
        <Flex alignItems="center">
            <AccountNameText data-qa-id="account-menu-title" color="currentColor" mr="xs">
                {status === 'pending' ? (
                    'Loading...'
                ) : status === 'success' ? (
                    <>
                        {data?.name?.first} <DesktopOnlyName>{data?.name?.last}</DesktopOnlyName>
                    </>
                ) : (
                    'Account settings'
                )}
            </AccountNameText>
        </Flex>
    );
};

const Button = styled(AccordionItemButton)`
    ${({ theme, 'aria-expanded': ariaExpanded }) => theme.components.secondaryNav.button({ theme, ariaExpanded })}
`;

const ContentWrapper = styled(AccordionItemContentWrapper)`
    ${({ theme }) => theme.components.secondaryNav.contentWrapper}
`;

const NavList = styled.ul`
    ${({ theme }) => theme.components.secondaryNav.navList}
`;

const MobileOnlyLi = styled.li`
    ${cssSystem({ display: ['block', null, 'none'] })}
`;

const SecondaryNav = () => {
    const { pathname } = useLocation();
    const { mutate: logout } = useLogout();
    const [isOpen, toggleOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleSignOut: MouseEventHandler<HTMLAnchorElement> = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logout();
    };

    useEffect(() => {
        const closeAccordion = (evt: MouseEvent) => {
            const target = evt.target as HTMLElement;
            // if clicked outside the menu, then close it
            if (ref.current && !ref.current.contains(target) && isOpen) {
                toggleOpen(false);
            }
        };

        window.addEventListener('click', closeAccordion);
        return () => {
            window.removeEventListener('click', closeAccordion);
        };
    }, [isOpen]);

    useEffect(() => {
        toggleOpen(false);
    }, [pathname]);

    return (
        <AccordionItem
            zIndex={999}
            wrapperRef={ref}
            isOpen={isOpen}
            onClick={() => toggleOpen(!isOpen)}
            heading={<AccountMenu />}
            Button={Button}
            ContentWrapper={ContentWrapper}
            minWidth={0}
            position={['static', null, 'relative']}
            pl={[0, 0, 0]}
            pr={[0, 0, 0]}
            data-qa-id="secondary-nav"
        >
            <Box p="md" data-qa-id="nav-content">
                <NavList>
                    <li>
                        <RouterLink to={PATHS.CONTACT_DETAILS} data-qa-id="sn-contact-details">
                            My contact details
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to={PATHS.PERMISSIONS} data-qa-id="sn-permissions">
                            Open Banking permissions
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to={PATHS.PREFERENCES} data-qa-id="sn-preferences">
                            Account settings
                        </RouterLink>
                    </li>
                    <MobileOnlyLi>
                        <RouterLink to={PATHS.HELP} data-qa-id="sn-help">
                            Help
                        </RouterLink>
                    </MobileOnlyLi>
                    <li>
                        <RouterLink onClick={handleSignOut} to={PATHS.LOGOUT} data-qa-id="sn-sign-out">
                            Sign out
                        </RouterLink>
                    </li>
                </NavList>
            </Box>
        </AccordionItem>
    );
};

export default SecondaryNav;
