import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RRDLink } from 'react-router-dom';
import { css, styled } from 'styled-components';

import type { LinkProps } from '@cof/plastic-components';
import { Link } from '@cof/plastic-components';

// Required until we can use the plastic Links directly - see https://github.cloud.capitalone.com/uk-web-libraries/plastic-components/issues/1000
const StyledLink = styled(RRDLink)<RouterLinkProps & LinkProps>`
    ${({ theme, size, variant }) => css`
        ${theme.components.link.base}
        ${theme.components.link[size || 'medium']}
        ${theme.components.link[variant || 'dark']}
    `}
`;

const RouterLink = ({ to, ...otherProps }: { to: string } & Omit<RouterLinkProps, 'to'> & LinkProps) => {
    /**
     * While some pages/routes are on old web servicing we can dynamically display react-router-dom links or standard
     * external links depending on whether the path starts with "http" or not
     */
    return to.toString().startsWith('http') ? (
        <Link href={to} external={false} {...otherProps} />
    ) : (
        <StyledLink to={to} {...otherProps} />
    );
};

export default RouterLink;
