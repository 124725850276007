import { sharedTheme } from '@cof/plastic-components';

import c1Theme, { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { littlewoods, global },
} = sharedTheme;

const header = {
    background: {
        mobile: global.white,
        desktop: littlewoods.blue750,
    },
    border: {
        mobile: global.grey200,
    },
    helpIcon: global.white,
    icon: {
        active: 'currentColor',
        inactive: 'currentColor',
    },
    text: {
        mobile: global.black,
        desktop: global.black,
    },
    link: {
        border: {
            mobile: global.grey200,
        },
        color: {
            mobile: global.black,
            desktop: global.black,
        },
        active: {
            mobile: littlewoods.purple550,
            desktop: littlewoods.purple550,
            border: global.white,
        },
        background: {
            mobile: global.white,
            desktop: global.transparent,
            active: {
                mobile: global.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
    },
    navBar: {
        border: {
            tablet: global.white,
        },
        background: {
            mobile: global.grey50,
            desktop: global.transparent,
        },
    },
};

const pageHeader = {
    row: global.grey50,
    text: littlewoods.purple550,
    emergencyBanner: {
        background: littlewoods.teal600,
    },
};

const defaultExport: ComponentColors = {
    ...c1Theme,
    header,
    pageHeader,
};

export default defaultExport;
