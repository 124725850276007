import { useTheme } from 'styled-components';

import { BRANDS, Box, Flex, InfoIcon, Link, PageRow, THEME_CONSTANTS, Text } from '@cof/plastic-components';

import { PageHeaderMainContent, PageHeaderRow, PageMainHeading } from '../../../atoms/PageHeader/PageHeader';
import { useAtAGlanceData } from '../../../data/atAGlance';
import { useBasicProfileData } from '../../../data/basicProfile';
import { BRAND_MAP_PLASTIC_TO_WS } from '../../../utils/brand';
import { NON_BRANDED_LINKS } from '../../../utils/constants';
import { titleCase } from '../../../utils/formatters';
import ContextualMessage from './ContextualMessages';

const HEADER_CUTOUT_HEIGHT = '5rem';
const { SCREEN_WIDTHS } = THEME_CONSTANTS;

export const getTimeOfDayGreeting = (firstName?: string) => {
    const now = new Date();
    let greeting;
    if (now.getHours() < 12) {
        greeting = 'Good morning';
    } else if (now.getHours() < 18) {
        greeting = 'Good afternoon';
    } else {
        greeting = 'Good evening';
    }
    return greeting + (firstName ? `, ${firstName}` : '');
};

export const EmergencyBanner = () => {
    const theme = useTheme();
    return (
        <Flex
            alignItems="center"
            bg={theme.componentColors.pageHeader.emergencyBanner.background}
            p="sm"
            height={HEADER_CUTOUT_HEIGHT}
        >
            <Box flexShrink={0}>
                <InfoIcon color="global.white" />
            </Box>
            <Text
                pl="xs"
                color="global.white"
                textAlign="left"
                fontWeight="medium"
                data-qa-id="account-summary-emergency-banner-text"
            >
                Has the rising cost of living impacted you?{' '}
                <Text color="global.white" textAlign="left" fontWeight="regular">
                    If so,{' '}
                </Text>
                <Link
                    external
                    variant="light"
                    href={NON_BRANDED_LINKS.COST_OF_LIVING}
                    data-qa-id="account-summary-emergency-banner-link"
                >
                    see how we can help
                </Link>
            </Text>
        </Flex>
    );
};

const noHumanity = [
    BRAND_MAP_PLASTIC_TO_WS[BRANDS.POST_OFFICE],
    BRAND_MAP_PLASTIC_TO_WS[BRANDS.LITTLEWOODS],
    BRAND_MAP_PLASTIC_TO_WS[BRANDS.VERY],
];

export default () => {
    const { data: profileData } = useBasicProfileData();
    const { data: atAGlanceData } = useAtAGlanceData();
    const theme = useTheme();

    return (
        <>
            <PageHeaderRow>
                <PageHeaderMainContent pb={[null, `1.4rem`]}>
                    <PageMainHeading data-qa-id="humanity-message">
                        {noHumanity.includes(atAGlanceData?.brand ?? BRAND_MAP_PLASTIC_TO_WS[BRANDS.CAPITAL_ONE])
                            ? 'Your Credit Card Account'
                            : getTimeOfDayGreeting(titleCase(profileData?.name?.first))}
                    </PageMainHeading>
                    <ContextualMessage />
                </PageHeaderMainContent>
            </PageHeaderRow>
            <PageRow maxWidth={SCREEN_WIDTHS.TEN_COLUMN} py={0} px={0} wrapperBg={theme.componentColors.pageHeader.row}>
                <EmergencyBanner />
            </PageRow>
        </>
    );
};
