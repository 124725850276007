import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { globalStyles, styleReset } from '@cof/plastic-components';
import '@cof/plastic-components/fonts.css';

import App from './App';
import ErrorBoundary from './pages/ErrorPages/ErrorBoundary';
import { BrandProvider, ThemeProvider, UpdateBrandProvider } from './utils/brand';
import reportError from './utils/reportError';

const GlobalStyle = createGlobalStyle`
    ${styleReset}
    ${globalStyles}
    #root {
        height: 100%;
    }
`;

const isRunningInCypress = !!window.Cypress;

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        // Use `meta` from query context to turn off error reporting for certain endpoints:
        // Report errors by default, only ignore errors if shouldReportError is explicitly set to false
        onError: (cause: unknown, query) => {
            if (query?.meta?.shouldReportError !== false) {
                reportError(cause);
            }
        },
    }),
    mutationCache: new MutationCache({
        onError: (cause: unknown, _, context, mutation) => {
            if (mutation?.meta?.shouldReportError !== false) {
                reportError(cause);
            }
        },
    }),
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: isRunningInCypress ? false : 3,
        },
        mutations: {
            retry: isRunningInCypress ? false : undefined,
        },
    },
});

if (isRunningInCypress) {
    window.queryClient = queryClient;
}

/**
 * Sets up orchestration API stubs when not build for PRODUCTION
 */
const prepare = async (): Promise<ServiceWorkerRegistration | void> => {
    if (import.meta.env.DEV || import.meta.env.MODE === 'test') {
        const { default: worker } = await import('../stubs/browser');
        return worker.start({
            onUnhandledRequest: ({ url }, print) => {
                if (!url.includes(window.config.orchestration.url)) {
                    return;
                }
                print.warning();
            },
        });
    }
    return Promise.resolve();
};

prepare().then(() => {
    const router = createBrowserRouter([
        {
            path: '/*',
            element: (
                <>
                    <ScrollRestoration />
                    <App />
                </>
            ),
            errorElement: <ErrorBoundary isAuthenticated={false} />,
        },
    ]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <UpdateBrandProvider>
                    <BrandProvider>
                        <ThemeProvider>
                            <GlobalStyle />
                            <RouterProvider router={router} />
                        </ThemeProvider>
                    </BrandProvider>
                </UpdateBrandProvider>
            </QueryClientProvider>
        </React.StrictMode>,
    );
});
