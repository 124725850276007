import type { ComponentType, ReactNode } from 'react';
import { styled } from 'styled-components';

import { Box } from '@cof/plastic-components';

const BaseStyledBox = styled(Box)`
    padding: 0.3rem 0;
    & > * {
        margin-top: 0.3rem;
    }
    & > :first-child {
        margin-top: 0;
    }
`;

const LightStyledBox = styled(BaseStyledBox)`
    ${({ theme }) => `
        background-color: ${theme.componentColors.box.background};

        & > * {
            background-color: ${theme.componentColors.dataRow.light.background || theme.colors.global.white};
        }
    `}
`;

const DarkStyledBox = styled(BaseStyledBox)`
    ${({ theme }) => `
        background-color: ${theme.colors.global.white};
        & > * {
            background-color: ${theme.componentColors.dataRow.dark.background || theme.componentColors.box.background};
        }
    `}
`;

export default ({
    children,
    variant = 'light',
    ...otherProps
}: {
    children: ReactNode;
    variant?: 'light' | 'dark';
    as?: string | ComponentType;
}) => {
    return variant === 'light' ? (
        <LightStyledBox {...otherProps}>{children}</LightStyledBox>
    ) : (
        <DarkStyledBox {...otherProps}>{children}</DarkStyledBox>
    );
};
