import { NavLink as BaseNavLink, NavLinkProps as BaseNavLinkProps } from 'react-router-dom';
import { css, styled } from 'styled-components';
import {
    DisplayProps,
    WidthProps,
    compose,
    display as ssDisplay,
    space as ssSpace,
    typography as ssTypography,
    width as ssWidth,
} from 'styled-system';

import { Link, LinkProps, ResponsiveSpaceProps } from '@cof/plastic-components';

export type NavLinkProps = { to: string } & Omit<BaseNavLinkProps, 'to'> &
    ResponsiveSpaceProps &
    LinkProps &
    DisplayProps &
    WidthProps;

const StyledNavLink = styled(BaseNavLink)<NavLinkProps>`
    ${({ theme }) => css`
        ${theme.components.header.navLinks}
        ${compose(ssTypography, ssSpace, ssDisplay, ssWidth)}
    `}
`;

const StyledExternalLink = styled(Link)<LinkProps>`
    ${({ theme }) => css`
        ${theme.components.header.navLinks}
        ${compose(ssTypography, ssSpace, ssDisplay, ssWidth)}
    `}
`;

const NavLink: React.FC<NavLinkProps> = ({ to, ...otherProps }) => {
    return to.toString().startsWith('http') ? (
        <StyledExternalLink href={to} external={false} {...otherProps} />
    ) : (
        <StyledNavLink to={to} {...otherProps} />
    );
};

export default NavLink;
