import { styled } from 'styled-components';

import { Accordion, Box, InfoPanel, OneColumn, Text } from '@cof/plastic-components';

import { PendingTransactionItem, usePendingTransactionsData } from '../../../data/transactions';
import Transaction from './Transaction';

const TransactionListHeading = styled(Box)`
    ${({ theme }) => theme.components.infoPanel.panelBackground}
`;

export function PendingTransactions() {
    const pendingTransactions = usePendingTransactionsData();

    if (pendingTransactions.isPending) {
        return (
            <TransactionListHeading>
                <Text>Loading pending transactions...</Text>
            </TransactionListHeading>
        );
    } else if (pendingTransactions.isError) {
        return (
            <TransactionListHeading>
                <Text data-qa-id="pending-transaction-error">
                    Sorry! There was an error retrieving your pending transactions.
                </Text>
            </TransactionListHeading>
        );
    }

    return (
        <OneColumn
            Content={
                <>
                    {pendingTransactions.data?.items?.length && pendingTransactions.data?.items?.length > 0 ? (
                        <>
                            <TransactionListHeading>
                                <Text data-qa-id="pending-transactions-label">Pending transactions</Text>
                            </TransactionListHeading>
                            <Accordion
                                data-qa-id="pending-transaction-items"
                                items={pendingTransactions.data.items.map(
                                    ({ name, location, value, classification }: PendingTransactionItem, index) => (
                                        <Transaction
                                            key={index}
                                            name={name}
                                            location={location}
                                            value={value}
                                            classification={classification}
                                        />
                                    ),
                                )}
                            />
                        </>
                    ) : (
                        <InfoPanel>
                            <Text>You have no pending transactions.</Text>
                        </InfoPanel>
                    )}
                </>
            }
        />
    );
}
