export const REDIRECT_URI_KEY = 'redirect_uri';

export const verifyRedirectURI = (redirectUri: string | null) => {
    const redirectUriAllowList: string[] = window.config?.redirectUriAllowList;
    return redirectUriAllowList?.find((url: string) => redirectUri?.includes(url));
};

export const addRedirectURI = (redirectUriParams: string) => {
    const redirectUriConfigMatch = verifyRedirectURI(redirectUriParams);
    if (redirectUriConfigMatch) {
        localStorage.setItem(REDIRECT_URI_KEY, redirectUriConfigMatch);
    }
};
