import cssSystem from '@styled-system/css';
import type { DefaultTheme } from 'styled-components';
import { css } from 'styled-components';

export default {
    transactionSummary: ({ theme, classification }: { theme: DefaultTheme; classification: string }) => css`
        box-shadow: -0.4rem 0 ${theme.componentColors.transactions.flag[classification]};
        ${cssSystem({
            paddingLeft: ['xs', null],
            marginLeft: ['0.4rem'],
        })}

        /** Set the icon colour. */
        svg {
            color: ${theme.componentColors.transactions.icon};
        }

        &:hover {
            svg {
                color: ${theme.componentColors.transactions.icon};
            }
        }
    `,
    valueText: ({ theme, classification }: { theme: DefaultTheme; classification: string }) => css`
        color: ${theme.componentColors.transactions.text[classification]};
        text-decoration: none;
        display: inline-block;
        &:before {
            content: ${classification === 'credit' ? '"+ "' : '""'};
        }
    `,
};
