import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { styled } from 'styled-components';

import { CaretLeftIcon, LinkProps } from '@cof/plastic-components';

import RouterLink from '../RouterLink/RouterLink';

export const StyledLink = styled(RouterLink)`
    display: inline-flex;
    align-items: center;
`;

/**
 * Re-implementing the BackButton component from plastic because the existing component doesn't integrate with the
 * react-router-dom - see https://github.cloud.capitalone.com/uk-web-libraries/plastic-components/issues/1000
 * @param children
 * @param otherProps
 * @constructor
 */
const RouterBackButton = ({ children = 'Back', ...otherProps }: RouterLinkProps & LinkProps) => (
    <StyledLink {...otherProps}>
        <CaretLeftIcon data-qa-id="backbutton-link-icon" pr="xs" height={['16px', null, '18px']} />
        <span data-qa-id="back-button">{children}</span>
    </StyledLink>
);

export default RouterBackButton;
