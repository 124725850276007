import { sharedTheme } from '@cof/plastic-components';

import c1Theme, { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { luma, global },
} = sharedTheme;

const header = {
    helpIcon: global.white,
    icon: {
        active: 'currentColor',
        inactive: 'currentColor',
    },
    background: {
        mobile: global.white,
        desktop: luma.blue750,
    },
    border: {
        mobile: global.grey200,
    },
    text: {
        mobile: global.white,
        desktop: global.white,
    },
    link: {
        color: {
            mobile: global.white,
            desktop: global.white,
        },
        active: {
            mobile: global.grey600,
            desktop: global.white,
            border: global.white,
        },
        background: {
            mobile: luma.purple700,
            desktop: global.transparent,
            active: {
                mobile: global.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
        border: {
            mobile: global.grey200,
        },
    },
    navBar: {
        border: {
            tablet: global.white,
        },
        background: {
            mobile: global.grey50,
            desktop: global.transparent,
        },
    },
};

const pageHeader = {
    row: global.grey50,
    text: luma.purple500,
    emergencyBanner: {
        background: luma.teal600,
    },
};

const defaultExport: ComponentColors = {
    ...c1Theme,
    header,
    pageHeader,
};

export default defaultExport;
