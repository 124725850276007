import { sharedTheme } from '@cof/plastic-components';

import thinkMoneyTheme, { ComponentColors } from '../capitalOne/componentColors';

const {
    colors: { thinkMoney, global },
} = sharedTheme;

const header = {
    border: {
        mobile: global.grey50,
    },
    text: {
        mobile: global.white,
        desktop: global.white,
    },
    link: {
        border: {
            mobile: global.grey50,
        },
        color: {
            mobile: global.white,
            desktop: global.grey50,
        },
        active: {
            mobile: global.black,
            desktop: global.white,
            border: global.white,
        },
        background: {
            mobile: global.transparent,
            desktop: global.transparent,
            active: {
                mobile: global.grey50,
                desktop: global.transparent,
                border: global.white,
            },
        },
    },
    navBar: {
        border: {
            tablet: global.white,
        },
        background: {
            mobile: thinkMoney.blue400,
            desktop: global.transparent,
        },
    },
    icon: {
        active: thinkMoney.blue400,
        inactive: 'currentColor',
    },
    background: {
        mobile: global.white,
        desktop: thinkMoney.blue750,
    },
    helpIcon: global.white,
};

const pageHeader = {
    row: global.grey50,
    text: thinkMoney.blue400,
    emergencyBanner: {
        background: thinkMoney.teal600,
    },
};

const transactions = {
    text: {
        credit: thinkMoney.green650,
        standard: thinkMoney.grey200,
        overlimit: thinkMoney.red550,
        link: thinkMoney.teal600,
        debit: thinkMoney.red650,
    },
    flag: {
        credit: thinkMoney.green650,
        standard: global.transparent,
        overlimit: thinkMoney.red550,
        link: thinkMoney.teal600,
        debit: thinkMoney.red650,
    },
    icon: thinkMoney.teal600,
};

const defaultExport: ComponentColors = {
    ...thinkMoneyTheme,
    header,
    pageHeader,
    transactions,
};

export default defaultExport;
