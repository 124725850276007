import { styled, useTheme } from 'styled-components';

import {
    BRANDS,
    Box,
    BrandedHeaderProps,
    DocumentIcon,
    EyeIcon,
    Flex,
    FullCardIcon,
    InfoIcon,
    OceanLogoPrimary,
    PaymentsIcon,
    BrandedHeader as PlasticBrandedHeader,
    BrandedLogo as PlasticBrandedLogo,
    THEME_CONSTANTS,
    VeryLogoPrimary,
    useBrand,
} from '@cof/plastic-components';

import NavLink, { NavLinkProps } from '../../atoms/NavLink';
import NavText from '../../atoms/NavText';
import { BRAND_LOGO_ALLOWLIST } from '../../utils/brand';
import { PATHS } from '../../utils/constants';
import SecondaryNav from '../SecondaryNav';

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

const linkProps: Partial<NavLinkProps> = {
    ml: [0, null, null, 'sm'],
};

const NavLinks = () => (
    <>
        <NavLink data-qa-id="at-a-glance-link" to={PATHS.AT_A_GLANCE} end {...linkProps}>
            <EyeIcon display={['block', null, 'none']} />
            At a glance
        </NavLink>
        <NavLink data-qa-id="statement-link" to={PATHS.STATEMENTS} {...linkProps}>
            <DocumentIcon display={['block', null, 'none']} />
            Statements
        </NavLink>
        <NavLink data-qa-id="payments-link" to={PATHS.PAYMENTS} {...linkProps}>
            <PaymentsIcon display={['block', null, 'none']} />
            Payments
        </NavLink>
        <NavLink data-qa-id="mycard-link" to={PATHS.MY_CARD} {...linkProps}>
            <FullCardIcon display={['block', null, 'none']} />
            My card
        </NavLink>
    </>
);

const NavBar = styled(Box)`
    ${({ theme }) => theme.components.header.navBar}
`;

export const NavBarContent = styled(Flex)`
    ${({ theme }) => theme.components.header.navBarContent}
`;

export const BrandedLogo = () => {
    const brand = useBrand(BRAND_LOGO_ALLOWLIST);
    return (
        <>
            {brand === BRANDS.LITTLEWOODS ? (
                // Littlewoods needs its own specific styling
                <PlasticBrandedLogo
                    allowList={BRAND_LOGO_ALLOWLIST}
                    logoProps={{
                        primaryColor: ['littlewoods.purple550', 'littlewoods.purple550', 'global.white'],
                    }}
                    maxWidth="12rem"
                />
            ) : brand == BRANDS.VERY ? (
                <VeryLogoPrimary />
            ) : brand === BRANDS.OCEAN ? (
                <OceanLogoPrimary />
            ) : (
                <PlasticBrandedLogo allowList={BRAND_LOGO_ALLOWLIST} />
            )}
        </>
    );
};

export const BrandedHeader = (props: BrandedHeaderProps) => (
    <PlasticBrandedHeader maxWidth={SCREEN_WIDTHS.TEN_COLUMN} bordered Logo={BrandedLogo} {...props} />
);

const Header = () => {
    const theme = useTheme();

    return (
        <>
            <BrandedHeader>
                <Flex
                    data-qa-id="header-main-nav"
                    display={['none', null, null, 'flex']}
                    flexShrink={0}
                    pl="lg"
                    justifyContent="space-between"
                >
                    <NavLinks />
                </Flex>
                <Flex data-qa-id="header-secondary-nav" alignItems="center" pl={[0, null, 'md']} ml="auto">
                    <Box flexShrink={0} display={['none', null, 'inline-block']}>
                        <NavLink data-qa-id="help-link" to={PATHS.HELP} ml={0} p={[0, 0, 0]}>
                            <InfoIcon
                                verticalAlign="text-bottom"
                                size="2rem"
                                color={theme.componentColors.header.helpIcon}
                            />
                            <NavText ml="xs">Help</NavText>
                        </NavLink>
                    </Box>
                    <NavText px="xs" aria-hidden display={['none', null, 'inline-block']}>
                        |
                    </NavText>
                    <SecondaryNav />
                </Flex>
            </BrandedHeader>
            <NavBar as="header">
                <NavBarContent>
                    <NavLinks />
                </NavBarContent>
            </NavBar>
        </>
    );
};

export default Header;
