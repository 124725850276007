import { css, styled } from 'styled-components';
import { space } from 'styled-system';

import { ResponsiveSpaceProps, Text } from '@cof/plastic-components';

export type NavTextProps = ResponsiveSpaceProps;

const NavText = styled(Text)<NavTextProps>`
    ${({ theme }) => css`
        ${theme.components.header.navText}
        ${space}
    `}
`;

export default NavText;
