import cssSystem from '@styled-system/css';
import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

const getColors = (theme: DefaultTheme) => theme.componentColors.secondaryNav;

export default {
    navList: ({ theme }: { theme: DefaultTheme }) => css`
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: ${theme.space.sm};

            &:last-child {
                margin-bottom: 0;
            }
        }
    `,
    badge: ({ theme }: { theme: DefaultTheme }) =>
        cssSystem({
            size: [24, 24, 24],
            color: getColors(theme).badge.color,
            backgroundColor: getColors(theme).badge.backgroundColor,
        }),
    button: ({ theme, ariaExpanded }: { theme: DefaultTheme; ariaExpanded: boolean }) => {
        const buttonColors = getColors(theme).button;
        return css`
            ${cssSystem({
                alignItems: 'center',
                textDecoration: 'none',
                color: [buttonColors.default.mobile, null, buttonColors.default.desktop],
                minWidth: 0,
                marginBottom: 0,
                py: ['xs', null, 'sm'],
                pl: ['lg', null, 'xs'],
                pr: 0,
            })}

            svg {
                ${cssSystem({
                    color: [buttonColors.default.mobileIcon, null, buttonColors.default.desktopIcon],
                })}
            }

            &:hover {
                ${cssSystem({
                    color: [buttonColors.default.mobile, null, buttonColors.default.desktop],
                })}

                svg {
                    ${cssSystem({
                        color: [buttonColors.default.mobileIcon, null, buttonColors.default.desktopIcon],
                    })}
                }
            }

            ${ariaExpanded &&
            css`
                background: ${[buttonColors.backgroundColor]};
                ${theme.breakpoints.mq.tabletMin} {
                    &:focus {
                        border-radius: 0.4rem 0.4rem 0 0;
                    }
                }

                ${cssSystem({
                    color: [buttonColors.expanded, buttonColors.expanded, buttonColors.expanded],
                })}

                svg {
                    ${cssSystem({
                        color: [buttonColors.expandedIcon, buttonColors.expandedIcon, buttonColors.expandedIcon],
                    })}
                }

                &:hover {
                    ${cssSystem({
                        color: [buttonColors.expanded, buttonColors.expanded, buttonColors.expanded],
                    })}

                    svg {
                        ${cssSystem({
                            color: [buttonColors.expandedIcon, buttonColors.expandedIcon, buttonColors.expandedIcon],
                        })}
                    }
                }
            `}
        `;
    },
    contentWrapper: ({ theme }: { theme: DefaultTheme }) =>
        cssSystem({
            position: 'absolute',
            right: 0,
            backgroundColor: getColors(theme).contentWrapper.backgroundColor,
            borderBottomWidth: [1, 1, 0],
            borderBottomStyle: 'solid',
            borderBottomColor: getColors(theme).contentWrapper.border,
            paddingLeft: 0,
            zIndex: 1,
            top: ['6rem', null, '100%'],
            boxShadow: [null, null, '-5px 5px 5px 0 rgb(0 0 0 / 30%)'],
            width: ['100%', null, '30rem'],
        }),
};
