import { sharedTheme } from '@cof/plastic-components';
import type { AllowedColors } from '@cof/plastic-components';

const untypedColors = sharedTheme.colors;
const colors = untypedColors as Record<string, Record<string, AllowedColors>>;
const { global, capitalOne: c1 } = colors;

const paymentModal = {
    protectionFinanceIcon: c1.pink250,
};

const header = {
    background: {
        mobile: global.white,
        desktop: c1.blue750,
    },
    border: {
        mobile: global.grey100,
    },
    text: {
        mobile: c1.teal450,
        desktop: global.white,
    },
    helpIcon: global.white,
    icon: {
        active: 'currentColor',
        inactive: 'currentColor',
    },
    link: {
        border: {
            mobile: global.grey200,
        },
        color: {
            mobile: c1.teal600,
            desktop: global.white,
        },
        active: {
            mobile: global.white,
            desktop: global.white,
            border: global.white,
        },
        background: {
            mobile: global.transparent,
            desktop: global.transparent,
            active: {
                mobile: c1.blue750,
                desktop: global.transparent,
                border: global.white,
            },
        },
    },
    navBar: {
        border: {
            tablet: global.white,
        },
        background: {
            mobile: global.transparent,
            desktop: c1.blue750,
        },
    },
};

const balanceTransfer = {
    icon: c1.teal600,
    iconOnBtPage: c1.pink250,
};

const currencyConverter = {
    heading: {
        background: c1.blue750,
        text: global.white,
    },
    result: c1.teal600,
};

const pageHeader = {
    row: c1.blue750,
    text: global.white,
    emergencyBanner: {
        background: c1.teal600,
    },
};

const permissions = {
    permissionCard: {
        active: c1.teal450,
        inactive: c1.red500,
    },
    endDateBox: {
        active: c1.teal450,
        inactive: c1.red550,
    },
    accountPermissions: {
        icon: c1.teal600,
    },
};

const secondaryNav = {
    badge: {
        color: global.white,
        backgroundColor: c1.red500,
    },
    button: {
        default: {
            mobile: c1.teal600,
            mobileIcon: c1.teal600,
            desktop: global.white,
            desktopIcon: global.white,
        },
        expanded: c1.teal600,
        expandedIcon: c1.teal600,
        backgroundColor: global.white,
    },
    contentWrapper: {
        backgroundColor: global.white,
        border: global.grey100,
    },
};

const secureMessages = {
    noMessagesIcon: c1.teal600,
    customer: {
        background: c1.blue750,
        text: global.white,
    },
    messageHistoryBackground: global.grey50,
    emailIcon: c1.pink250,
    tableDivider: global.grey200,
    agent: {
        background: global.grey50,
        text: c1.blue750,
    },
    infoIcon: { 1: c1.orange500, 2: c1.teal600 },
};

const transactions = {
    text: {
        credit: c1.green650,
        standard: c1.blue750,
        overlimit: c1.red550,
        link: c1.teal600,
        debit: c1.red550,
    },
    flag: {
        credit: c1.green650,
        standard: global.transparent,
        overlimit: c1.red550,
        link: c1.teal600,
        debit: c1.red550,
    },
    icon: c1.teal600,
};

const box = {
    background: global.grey50,
    border: global.grey50,
};

const accordion = {
    background: global.grey50,
};

const infoPanel = {
    background: global.grey50,
};

const dataRow = {
    dark: {
        background: global.grey50,
    },
    light: {
        background: global.white,
    },
};

const exportDefault = {
    accordion,
    header,
    box,
    balanceTransfer,
    currencyConverter,
    dataRow,
    infoPanel,
    pageHeader,
    permissions,
    paymentModal,
    secondaryNav,
    secureMessages,
    transactions,
};

export type ComponentColors = typeof exportDefault;
export default exportDefault;
