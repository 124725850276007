import cssSystem from '@styled-system/css';
import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { NavBarContent } from '../../../molecules/Header/Header';

const getConfig = (theme: DefaultTheme) => theme.components.header.config;

export default {
    config: {
        activeLinkBorder: '-42px',
    },
    contentWrapper: () =>
        cssSystem({
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
            flexWrap: ['nowrap', null, 'nowrap'],
        }),
    navBar: ({ theme }: { theme: DefaultTheme }) => {
        const nb = theme.componentColors.header.navBar;
        return css`
            ${cssSystem({
                width: '100%',
                display: ['flex', null, null, 'none'],
                px: [0, null, 'lg'],
                backgroundColor: [nb.background.mobile, null, nb.background.desktop, nb.background.desktop],
            })}
        `;
    },
    navBarContent: ({ theme }: { theme: DefaultTheme }) => css`
        ${cssSystem({
            width: '100%',
            justifyContent: ['space-between', null, 'flex-start'],
            borderBottomWidth: [0, null, '.1rem'],
            borderBottomStyle: 'solid',
            borderBottomColor: theme.componentColors.header.navBar.border.tablet,
        })}
    `,
    navLinks: ({ theme }: { theme: DefaultTheme }) => {
        const nl = theme.componentColors.header.link;
        return css`
            ${theme.components.link.base}
            ${theme.components.link.medium}
            ${cssSystem({
                fontWeight: 'regular',
                backgroundColor: [nl.background.mobile, null, nl.background.desktop],
                color: [nl.color.mobile, null, nl.color.desktop],
                display: ['flex', null, 'inline-block'],
                flexGrow: 1,
                padding: [0, null, 'xs'],
                paddingY: ['sm', null, null, 0],
                paddingBottom: ['sm', null, '1.8rem', 0],
                position: 'relative',
                textAlign: 'center',
                textDecoration: 'none',
            })}
        
            &:after {
                content: ' ';
                height: 0;
                width: 100%;
                z-index: 10;
                position: absolute;
                left: 0;
                right: 0;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                ${cssSystem({
                    backgroundColor: nl.active.border,
                    bottom: [0, 0, getConfig(theme).activeLinkBorder],
                    display: ['none', null, 'block'],
                })}
            }

            ${NavBarContent} &:after {
                ${cssSystem({
                    bottom: 0,
                })}
            }

            svg {
                fill: ${theme.componentColors.header.icon.inactive};
            }

            &:hover,
            &.active,
            &:focus {
                text-decoration: none;
                ${cssSystem({
                    fontWeight: 'semiBold',
                    backgroundColor: [nl.background.active.mobile, null, nl.background.active.desktop],
                    color: [nl.active.mobile, null, nl.active.desktop],
                })}

                svg {
                    fill: ${theme.componentColors.header.icon.active};
                }

                &:after {
                    opacity: 1;
                    height: 5px;
                }
            }

            ${theme.breakpoints.mq.mobileMax} {
                border: 0.15rem solid ${theme.componentColors.header.border.mobile};
                border-top: none;
                border-bottom: none;
                flex-direction: column;
                align-items: center;
                width: 25%;

                &:last-child {
                    border-right: none;
                }
                &:first-child {
                    border-left: none;
                }

                &:hover,
                &.active,
                &:focus {
                    border-radius: 0;
                    z-index: 5;
                    border-color: transparent;
                }

                &.active {
                    z-index: 6;
                }
            }
        `;
    },
    navText: ({ theme }: { theme: DefaultTheme }) => css`
        ${theme.components.text.base}
        ${theme.components.text.medium}        
        ${cssSystem({
            color: [theme.componentColors.header.text.mobile, null, theme.componentColors.header.text.desktop],
        })}
    `,
};
