import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';

export const sessionInfoValidator = z.object({
    authLevel: z.number(),
    timeToLive: z.number(),
});
export type SessionData = z.infer<typeof sessionInfoValidator>;

export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const useCreateOrGetSession = () => {
    return useQuery({
        queryKey: ['session'],
        queryFn: async () => {
            const path = ORCH_PATHS.SESSION;
            try {
                const { data } = await orchestration.get(path);
                return sessionInfoValidator.parse(data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
        staleTime: Infinity,
    });
};
