import { Link } from 'react-router-dom';

import type { ButtonProps } from '@cof/plastic-components';
import { Button } from '@cof/plastic-components';

import { useAtAGlanceData } from '../../data/atAGlance';
import { PATHS } from '../../utils/constants';

const MakeAPaymentButton = (props: ButtonProps) => {
    const { data, isPending, isError } = useAtAGlanceData();

    if (isPending || isError) return <></>;

    const shouldShowComponent = data && data.currentBalance.amount > 0;
    if (!shouldShowComponent) return <></>;

    return (
        /* @ts-ignore: ignores react-router 'to' prop which doesn't exist on Button */
        <Button as={Link} to={PATHS.MAKE_PAYMENT} data-qa-id="make-a-payment-button" {...props}>
            Make a payment
        </Button>
    );
};

export default MakeAPaymentButton;
