import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';

const basicProfileValidator = z.object({
    name: z.object({
        first: z.string(),
        last: z.string(),
    }),
    totalUnreadMessageCount: z.number().optional(),
});

export type BasicProfileData = z.infer<typeof basicProfileValidator>;

export const useBasicProfileData = () => {
    return useQuery({
        queryKey: ['basic-profile'],
        queryFn: async () => {
            const path = ORCH_PATHS.BASIC_PROFILE;
            try {
                const response = await orchestration.get(path);
                return basicProfileValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};
