import { isAxiosError } from 'axios';
import { z } from 'zod';

/**
 * Base error class which adds the `extraContext` attribute to send metadata about the error to New Relic
 */
export class ReportableError extends Error {
    extraContext;

    constructor(
        message?: string | undefined,
        options?: (ErrorOptions & { extraContext?: Record<string, string | number> | undefined }) | undefined,
    ) {
        // Need to pass `options` as the second parameter to install the "cause" property.
        super(message, options);
        this.extraContext = options?.extraContext;
        this.name = 'ReportableError';
    }
}

/**
 * Handles unexpected/unknown error types
 */
export class UnknownError extends ReportableError {
    constructor(...args: ConstructorParameters<typeof ReportableError>) {
        // Need to pass `options` as the second parameter to install the "cause" property.
        super(...args);
        this.name = 'UnknownError';
    }
}

/**
 * Error class for dealing with axios/network errors
 */
export class ApiError extends ReportableError {
    constructor(...args: ConstructorParameters<typeof ReportableError>) {
        // Need to pass `options` as the second parameter to install the "cause" property.
        super(...args);
        this.name = 'ApiError';
    }
}

/**
 * Error class for handling zod validation errors for API responses
 */
export class ResponseValidationError extends ReportableError {
    constructor(...args: ConstructorParameters<typeof ReportableError>) {
        // Need to pass `options` as the second parameter to install the "cause" property.
        super(...args);
        this.name = 'ResponseValidationError';
    }
}

/**
 * Creates ReportableError of the right type for this
 * @param cause
 * @param extraContext
 */
export const createError = (
    cause: unknown,
    extraContext?: Record<string, string | number> | undefined,
): ReportableError => {
    let error;
    if (cause instanceof z.ZodError) {
        error = new ResponseValidationError(
            `API response validation failure. Issue list: ${JSON.stringify(cause.issues, null, 2)}`,
            {
                cause,
                extraContext,
            },
        );
    } else if (isAxiosError(cause)) {
        error = new ApiError(
            `with Axios '${cause.config?.method}' request to '${cause.config?.baseURL}${cause.config?.url}'. Reason: ${cause.message}`,
            {
                cause,
                extraContext: {
                    method: cause.config?.method ?? 'N/A',
                    responseCode: cause.response?.status ?? 'N/A',
                    ...extraContext,
                },
            },
        );
    } else if (cause instanceof Error) {
        error = new UnknownError(cause.message, { cause, extraContext });
    } else if (typeof cause === 'string') {
        error = new UnknownError(cause, { extraContext });
    } else {
        error = new UnknownError('Error created from unknown object type', { extraContext });
    }
    return error;
};

const reportError = (error: unknown | typeof ReportableError) => {
    if (window.newrelic && !window.Cypress) {
        try {
            const safeError = error instanceof ReportableError ? error : createError(error);
            window.newrelic.noticeError(safeError, safeError.extraContext);
        } catch {}
    }
};

export default reportError;
