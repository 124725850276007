import cssSystem from '@styled-system/css';
import { css } from 'styled-components';

export default {
    borderedPanel: () => css`
        ${cssSystem({
            width: '100%',
            maxWidth: '60rem',
            marginBottom: 'md',
            marginX: 'auto',
            padding: 'lg',
            borderStyle: 'solid',
            borderWidth: '0.2rem',
            borderColor: 'global.grey50',
        })}
    `,
};
